import { useContext, useEffect, useState } from "react";
import { DataTable } from "../../components/bootstrap/data-table";
import { LoadingOverlay } from "../../components/layout/loading-overlay";
import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { AuthorisedPage } from "../auth/authorised-page";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { Link } from "react-router-dom";
import { CoreServiceSelector } from "../../services/core/service-selector";


const iconStyle = {
    display: "inline-block",
    width:"100%",
    textAlign: "center",
    fontSize: "21px"
};

const getReadyStatus = (row)=>{
    
    if(row.AccountIsCreated && row.LastAccessDate != null)
        return (
            <span
                title="account is ready"
                style={{...iconStyle, color: "green"}}  
                className="bi bi-check-circle-fill"
            >
            </span>
        );

    if(row.AccountIsCreated && !row.LastAccessDate && row.IsSent)
        return (
            <span
                title="account is ready"
                style={{...iconStyle, color: "orange"}}  
                className="bi bi-check-circle-fill"
            >
            </span>
        );
    
    if(row.AccountIsCreated && !row.LastAccessDate && !row.IsSent)
        return (
            <span
                title="waiting first login"
                style={{...iconStyle, color: "orange"}}  
                className="bi bi-exclamation-circle-fill"
            >
            </span>
        );
    return null;
};

const dataModel = {
    fields:[
        {
            name: 'x',
            label: 'Status',
            isCustom: true,
            render: (row)=>{
                return getReadyStatus(row);
            }
        },
        {
            name: 'x',
            label: '',
            isCustom: true,
            render: (row)=>{
                if(row.HasFullGroupAccess)
                    return (
                        <span
                            title="full group access"
                            style={{...iconStyle, color: "blue"}}  
                            className="bi bi-diagram-3-fill"
                        >
                        </span>
                    )
                return "";
            }
        },
        {
            name: 'FullName',
            label: 'Name',
            isCustom: true,
            render: (row)=>{
                return (
                    <Link target="_self"
                        to={
                        {
                            pathname:(`/edit-user/${row.Id}`)
                        }
                    }>
                    {row.FullName}
                  </Link>
                );
            }
        },
      
        {
            name: 'Email',
            label: 'Email'
        }, 
        {
            name: 'CompanyName',
            label: 'Venue'
        }, 
        {
            name: 'UserRoles',
            label: 'UserRoles'
        },
        
    ]
};

export const UsersListPage = ()=>{
    const [data, setData] = useState([]);
    const [pageState, setPageState] = useState({
        isLoading: true
    });
    const [unauthorised, setUnauthorised] = useState({show: false});
    const {state, dispatch} = useContext(CognitoContext);

    const loadUsers = async ()=>{
        
        const service = CoreServiceSelector.GetUserManagementService(state.session);
        
        if(!service)
            return;
        
        setPageState({
            ...pageState,
            isLoading: true
        });
        const result =  await service.getUsersList()
                                    .catch(err =>{
                                        if(err.response.status === 401){
                                            setUnauthorised({show: true});
                                        }
                                    });

        setData(result || []);
        setPageState({
            ...pageState,
            isLoading: false
        });

    };

    
    useEffect(()=>{
        loadUsers();
    },[state]);

    return (
        <AuthorisedPage>
            <PortalMainLayout title="Users List" isUnauthorised={unauthorised.show}>
                <div>
                    <LoadingOverlay isLoading={pageState.isLoading} title="Please wait" message="loading history">
                        <DataTable
                                fields={dataModel.fields}
                                rows={data}
                        />
                   </LoadingOverlay>
                </div>
            </PortalMainLayout>
        </AuthorisedPage>
    );
};


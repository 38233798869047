import { CoreSignupInfoService } from "../core/signup-info-service";

export class MockSignupInfoService extends CoreSignupInfoService
{
    constructor(session){
        super(session);
    }

    
    getSignupInfo = (id, guid)=>{
        const data = {
            "id":"144",
            "firstName":"Thomas",
            "lastName":"Anderson",
            "fullName": "Thomas Anderson",
            "email":"testuser@bestgiftgroup.com",
            "companyName":"Aurora Spa Retreat @ The Prince",
            "companyGuid":"83f98982-4c17-4a84-ab8c-cc78c3ad34b5",
            "phone":"+61786548765"
        };
        
        return new Promise(resolve =>{
            return resolve(data);
        });
    }

}
//https://dev.best-experiences.com/manage-vendor-users/api/user-access/user-menu

import { UserInfoService } from "../core/user-info-service";


export class DevUserInfoService extends UserInfoService
{
    constructor(session){
        super(session);
    }
    
    getUserMenuUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api/user-info/user-menu`;
    getUserInfoUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api/user-info`;

} 

import styled from "styled-components";
import { ToggleView } from "../views/toggle-view";

const OverlayContainer = styled.div`
    position: relative;
`;

const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0px;
    z-index: 900;
    background-color: black;
    opacity: 0.5;
    height: 150%;
    min-height: 500px;
    width: 100%;
    margin: 0;
`;


const OverlayDiv = styled.div`
    height: 100%;
`;

const SpinnerDiv = styled.div`
    height: 100%;
    z-index: 1000;
    margin-top: 10%;
    color: orange;  
`;


const SpinnerCircle = styled.div`
    z-index: 1000;
    width: 3.5rem;
    height: 3.5rem;
`;

export const LoadingOverlay = ({isLoading, title, message, children})=>{
   
    return (
    <OverlayContainer>
        <ToggleView show={isLoading}>
            <SpinnerOverlay>
                <OverlayDiv className="loader-container">
                    <SpinnerDiv className="text-center">
                        <SpinnerCircle className="spinner-border" role="status">
                            <span className="visually-hidden"></span>
                        </SpinnerCircle>
                        <div>
                            <h3>{title}</h3>
                            <p>{message}</p>
                        </div>
                    </SpinnerDiv>
                    
                </OverlayDiv>
            </SpinnerOverlay>
        </ToggleView>
        {
            children
        }

    </OverlayContainer>
    );
};

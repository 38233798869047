import { useContext, useState } from "react";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CognitoConfirmSignup, CognitoGetErrorMessage, CognitoResetPassword, ConfirmFirstPassword, ResendConfirmCode } from "../../library/cognito/confirm";
import { CurrentUserStorage } from "../../library/core/current-user";
import { Alert, Alerts } from "../bootstrap/alert";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { FormInput } from "../form/input";
import { PASSWORD_HINT, isValidPassword } from "../../library/core/utils";



const formView = {
  fields:[
      {
        name: "phone",
        label: "Phone number",
        type: 'tel',
        defaultValue: '+61',
        hint:'All phone numbers should be in international format e.g. +61234567890',
        required: true
      },
      {
          name: 'password',
          label: 'New Password',
          type: 'password',
          //hint: PASSWORD_HINT,
          required: true,
          validate: (value)=>{
            if(!isValidPassword(value))
              return PASSWORD_HINT;
            return null;
          }
      },
      {
        name: 'confirmPassword',
        label: 'Confirm password',
        type: 'password',
        required: true,
        validate: (value, dataModel)=> {
            if(value !== dataModel.password)
                return `passwords does not match!`;
            return null;
        }
      }
  ]
};



export const ForceResetPasswordForm = ({
  username,
  completeNewPasswordHandler,
  errorMessage,
  onSuccess,
  onCancel,
  onError
}) => {
    
    username = username || CurrentUserStorage.getCurrentUser();
    const [model, setModel] = useState({username});
    const [modelErrors, setModelErrors] = useState({}); 
    const cognitoContext = useContext(CognitoContext);

    const raiseError = (err)=> {
      onError && onError(err);
      return false;
    };

    

    const onConfirmHandler = (event)=>{
      event.preventDefault();
      
      const errs = ValidateFormView({fields: formView.fields, model});
      setModelErrors(errs);

      if(!errs || Object.keys(errs).length === 0)
      {
        completeNewPasswordHandler && completeNewPasswordHandler({
              username: model.username,
              newPassword:model.password,
              phoneNumber:model.phone,
              onError,
              onSuccess: ()=>{
                onSuccess && onSuccess({
                  username: model.username,
                  password: model.password
                });
              }
            });
      }

    };

    

  const onFieldChangeHandler = (event) => {
   
     setModel({
         ...model,
         [event.target.name]: GetEventInputValue(event)  
       });
  };

  return (
      <form>
        <h3>Change your password</h3>
        <div>
          <p style={{marginBottom:"0.2rem"}}>
            You are required to change your password to proceed into your account
          </p>
        </div>
        <Alert type={Alerts.danger} hide={!errorMessage}>
          <p>{errorMessage}</p>
        </Alert>

        {
            formView.fields.map(field => <FormInput 
                                            key={field.name} 
                                            dataModel={model} 
                                            {...field}
                                            errorsModel={modelErrors}
                                            onChange={onFieldChangeHandler}
                                            />
                                )
        }

       

        <div className="d-grid">
          <button type="submit" className="btn btn-primary" onClick={onConfirmHandler}>
            Update Password
          </button>
        </div>

        <div className="d-grid" style={{
          margin: "10px 0"
        }}>
          <button type="button" className="btn btn-secondary" onClick={onCancel}>
            Cancel
          </button>
        </div>
        
        
      </form>
    );
};

export const GetEventInputValue = (event, trimInput)=>{
    return (event.target.type === 'checkbox')? event.target.checked: (
        trimInput? event.target.value && event.target.value.trim(): event.target.value
    );
};


const ValidateForRequiredInput = ({isRequiredMessage, label, value})=>{
    
    if(!value || value.length === 0)
        return isRequiredMessage || `${label} can't be empty, please provide a valid ${label}`;
    return null; 
};


export const ValidateFormView = ({fields, model})=>{
    const errors = {};
    fields.forEach(field=>{
        let err = null;
        const value = model[field.name];
        if(field.required)
        {
            err = ValidateForRequiredInput({
                isRequiredMessage: field.isRequiredMessage,
                label: field.label || field.name,
                value
            });
    
            if(err)
                errors[field.name] = err;
        }

        if(!err && field.validate && typeof field.validate === 'function')
        {
            err = field.validate(value, model);
            if(err)
                errors[field.name] = err;
        }
    });

    return errors;
};
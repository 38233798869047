import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { AuthorisedPage } from "../auth/authorised-page";

export const CompanyProfilePage = ()=>{
    return (
        <AuthorisedPage>
            <PortalMainLayout title="Company Profile">
                
            </PortalMainLayout>
        </AuthorisedPage>
    );
};


import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { NavUserDropMenu } from "./drop-menu";
import { ToggleView } from "../views/toggle-view";
import { GetEnvironmentNavLabel } from "../../services/core/service-selector";
import brownLogo from '../../logo-brown.png';
import whiteLogo from '../../logo-white.png';

const mobileWidth = 425;

const NavSection = styled.nav`
  @media print { display: none;}
`;


const DesktopSection = styled.div`
    display: none;
    @media  (min-width: ${1+mobileWidth}px){
        display: inline-block;
    }
`;


const LogoImg = styled.img`
  max-width: 45px;
  display: inline-block;
  margin-right: 15px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
  font-size:22px;
`;

const BrandLink = styled(Link)`
  display: flex;
  > span {
      display: inline-block;
      min-height: 45px;
      line-height: 45px;
      font-size: 24px;
      @media (min-width: 0px) and (max-width:320px){
        font-size: 14px;
      }
  }
`;

const BrownLogo = <LogoImg src={brownLogo} alt="logo"/>
const WhiteLogo = <LogoImg src={whiteLogo} alt="logo"/>

const AppNavBar = (props)=>{

  const {state, dispatch} = useContext(CognitoContext);
  const isLoggedIn = state.session && state.cognitoUser;

  var reedemptionCode=props.redeem;
  let navClassName = "navbar navbar-expand-lg navbar-light fixed-top";
  if(isLoggedIn)
  {
      navClassName += " dark-navbar";

  }

    return (
      <>
        <NavSection className={navClassName}>
          <DesktopSection className="container">
            <BrandLink className="navbar-brand" to={'/'}>
              {isLoggedIn? WhiteLogo : BrownLogo}
              <span>Vendors Portal {GetEnvironmentNavLabel()}</span>             
            </BrandLink>
            <BoldSpan>{reedemptionCode}</BoldSpan>
            <NavUserDropMenu isLoggedIn={isLoggedIn}/>
          </DesktopSection>
        </NavSection>
      </>
    );
};

export {AppNavBar};
const CURRENT_USER_EMAIL = 'CURRENT_USER_EMAIL';

;

export class CurrentUserStorage{
    static saveCurrentUser = (email)=>{
        localStorage.setItem(CURRENT_USER_EMAIL, email);
    }

    static getCurrentUser = ()=>{
        return localStorage.getItem(CURRENT_USER_EMAIL);
    }

    static clearSession = ()=>{
        const keys = Object.keys(localStorage);
    
        keys.forEach(function(key){
            if(key !== CURRENT_USER_EMAIL)
            {
                localStorage.removeItem(key);
            }
         });
    
    }

};



import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { AuthorisedPage } from "../auth/authorised-page";

export const EnvVariablesPage = ()=>{
    const keys=Object.keys(process.env);

    return (
        <AuthorisedPage>
            <PortalMainLayout title="Env. Variables">
                
                <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>
                {
                    keys.map(k=>  (
                        <tr>
                            <td>{k}</td>
                            <td>{process.env[k]}</td>
                        </tr>)
                    )
                }

                    
                </tbody>
                </table>

            </PortalMainLayout>
        </AuthorisedPage>
    );
};


import { useContext, useState } from "react";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { ResendForgotPassword } from "../../library/cognito/confirm";
import { CurrentUserStorage } from "../../library/core/current-user";
import { Alert, Alerts } from "../bootstrap/alert";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { FormInput } from "../form/input";



const formView = {
  fields:[
      {
          name: "username",
          label: "Email address",
          type: 'email',
          required: true
      }
  ]
};


export const ForgotPasswordForm = ({
    username, 
    errorMessage,
    onProcceed,
    onCancel,
    onError
})=>{
    username = username || CurrentUserStorage.getCurrentUser();
    const cognitoContext = useContext(CognitoContext);
    const [model, setModel] = useState({username});
    const [modelErrors, setModelErrors] = useState({}); 

    const onFieldChangeHandler = (event) => {
        setModel({
            ...model,
            [event.target.name]: GetEventInputValue(event)  
          });
     };

    const onSendCodeHandler = (event)=>{
        event.preventDefault();
        
        const errs = ValidateFormView({fields: formView.fields, model});
        setModelErrors(errs);

        if(!errs || Object.keys(errs).length === 0)
        {
          ResendForgotPassword({ 
            cognitoContext: cognitoContext.state,
            username: model.username,
            onSuccess: ()=>{
                // switch to Reset Password Form
                onProcceed && onProcceed({username: model.username});
            },
            onError});
        }
    };

    return (
        <form>
          <h3>Forgot your password?</h3>
          
          <Alert type={Alerts.danger} hide={!errorMessage}>
            <p>{errorMessage}</p>
          </Alert>
  
          {
            formView.fields.map(field => <FormInput 
                                            key={field.name} 
                                            dataModel={model} 
                                            {...field}
                                            errorsModel={modelErrors}
                                            onChange={onFieldChangeHandler}
                                            />
                                )
          }
  
          <div className="d-grid">
            <button type="submit" className="btn btn-primary" onClick={onSendCodeHandler}>
              Send Code
            </button>
          </div>

         
        <div className="d-grid" style={{
            margin: "10px 0"
            }}
        >
            <button type="button" className="btn btn-secondary" onClick={onCancel}>
                Cancel
            </button>
        </div>

        
        </form>
      );
};

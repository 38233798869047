import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterHeading = styled.span`
  text-align:center;
  font-size:16px;
  font-weight:100;
`;
const FooterLink=styled.a`
  color:white;
  font-size:16px;
  font-weight:100;
`;

const FooterSpan=styled.span``;

const Footer=()=>
{
    let footerClassName = "bg-black text-white px-0 py-3 text-center fixed-bottom overflow-hidden";
return (
  <div className={footerClassName}>
    <div className="container">
      <FooterHeading>
       
        <span>
          <FooterLink href="https://bestgiftgroup.com/vendor-support/" target="_blank">
            <FooterSpan>Vendor support</FooterSpan>
          </FooterLink> |{" "}
          <FooterLink href="https://bestgiftgroup.com/terms-conditions-vendors/"  target="_blank">
            <FooterSpan>Terms & conditions</FooterSpan>
          </FooterLink>
        </span>
      </FooterHeading>
    </div>
  </div>
);
}
export default Footer;
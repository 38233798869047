const subMenus = [
    {
        category: 'main',
        role: 'redeem',
        title: 'Redeem a Card/Certificate',
        icon: 'bi-upc-scan',
        url:'/redeem-voucher'
    },
    {
        category: 'main',
        role: 'history',
        title: 'Redemption History',
        icon: 'bi-graph-up-arrow',
        url: '/redemption-history'
    },
    
    {
        category: 'useradmin',
        role: 'useradmin',
        title: 'Users',
        icon: 'bi-person-lines-fill',
        url: '/users-list'
    },
    {
        category: 'useradmin',
        role: 'useradmin',
        title: 'Add User',
        icon: 'bi-person-fill-add',
        url: '/add-user'
    }
];

export const isInUserRoles = (role, userRoles)=>{
    role ||= '';
    const rolesArray = role.split(",");

    const isInArray = (text, array)=>{
        return ((array || []).filter(item => item === text)).length > 0;
    };
    return ((userRoles || []).filter(item => isInArray(item, rolesArray))).length > 0;
};
export const getMenuItems=({category, userRoles})=>{
    return subMenus.filter(item => item.category === category && isInUserRoles(item.role, userRoles));
};

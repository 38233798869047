import { RedemptionService } from "../core/redemption-service";


export class ProdRedemptionService extends RedemptionService
{
    constructor(session){
        super(session);
    }
   
    getBaseUrl = ()=> `https://prod.best-experiences.com/withlogin-universal-redemptions/api`;

    
} 

import { CoreService } from "./core-service";
import { add, compareAsc, parseISO } from 'date-fns';
import { GetEnvironmentTag } from "./service-selector";

const LOCAL_STORAGE_ITEM = 'NO_LOGIN_LOCAL_STORAGE_ITEM';
const EXPIRE_SESSION_AFTER_MINUTES = 8*60;
// this is a comment

const isExpiredState = (state)=>{
    return (!state || !state.expiryDate || compareAsc(new Date(), parseISO(state.expiryDate)) > 0);
};





export class NoLoginService extends CoreService
{
    constructor({passCode, captcha}){
        super(null);
        this.passCode = passCode;
        this.captcha = captcha;
        this.mode = GetEnvironmentTag();
       
    }
   
    
    getBaseUrl = ()=> `https://${this.mode}.best-experiences.com/nologin-universal-redemptions/api`;

    getUserInfoUrl = ()=> `${this.getBaseUrl()}/user-info`;
    getRedeemUrl = () => `${this.getBaseUrl()}/universal-redemption/redeem-voucher`;
    getVoucherInfoUrl = ()=> `${this.getBaseUrl()}/voucher-info/get-voucher-info`;

    hasExpiredInfo = ()=> {
        const data = localStorage.getItem(LOCAL_STORAGE_ITEM);
        if(!data)
            return true;

        let state = JSON.parse(data);
        return isExpiredState(state);
    }

    getBaseHeaders() {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'x-pass-code': this.passCode,
            'token': this.captcha
        };

        return headers;
    }

    getState = ()=> {
        const data = localStorage.getItem(LOCAL_STORAGE_ITEM);
        
        if(data){
            let state = JSON.parse(data);
            if(isExpiredState(state)){
                return this.getDefaultState();
            }
                
            return state;
        }

        return this.getDefaultState();
    }

    saveState = (state)=>{
        localStorage.setItem(LOCAL_STORAGE_ITEM, JSON.stringify(state));
    }

    getDefaultState = ()=>{
        return {
            expiryDate: add(new Date(),{
                minutes: EXPIRE_SESSION_AFTER_MINUTES
            })
        };
    }

   
    getSavedUserInfo = ()=>{
        const state = this.getState();
        return state.userInfo;
    }

   
    getUserInfo = async (forceRefresh)=>{
        const state = this.getState();
        if(!forceRefresh && state && state.userInfo)
            return state.userInfo;

        const r = await this.callWebApi('GET', this.getUserInfoUrl(), null);

        state.userInfo = r.data;
        this.saveState(state);
        return r.data;
    }

    
    getVoucherInfo = async (barcode)=>{
        const result = await this.callWebApi(
            'POST', 
            this.getVoucherInfoUrl(), 
            {
                Barcode: barcode
            }
        );

        return result.data;
    }
    
    redeemCard = async (request)=>{
        const { 
            CardNumber,
            PinCode,
            Amount,
            Source,
            DeviceType,
            OsName,
            DeviceSerial,
            TotalBillAmount,
            TransactionGroupId,
            IsNewCustomer,
            AppVersion
        } = request;

        const result = await this.callWebApi(
            'POST', 
            this.getRedeemUrl(), 
            request
        );

        return result.data;
    }
} 

import mainLogo from '../logo-brown.png';
import styled from "styled-components";

const BODY = styled.div`
    font-family: Mulish, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    
    #container{
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 1em;
        padding-bottom: 1em;
    }


    @media (min-width: 1200px) {
        #container {
            width: 1170px;
        }
    }

    @media (min-width: 992px) {
        #container {
            width: 970px;
        }
    }

    @media (min-width: 768px) {
        #container {
            width: 750px;
        }
    }

   
    #underlined-header {
        padding-bottom: 15px;
        border-bottom: 3px black solid;
    }

    h1 {
        text-align: center;
        font-size: 48px;
        font-style: italic;
        font-weight: 200;
        margin-bottom: 0;
        font-family: Spectral, Georgia, Cambria, Times New Roman, Times, serif;
    }

    h2 {
        font-size: 33px;
        text-align: center;
        }

   
    #img-container{
        width: 100%;
        margin-bottom: 60px;
        &>img{
            margin: 0 auto; 
            max-width:150px;
            display: block;
        }
    }

    #notice{
        margin-top: 60px;
    }

    .p1{
        text-align: center; 
        font-size: 16px;
    }

    #f1{
        margin-top: 60px;
        margin-bottom: 10px;
    }

    .f2{
        text-align:center;
        margin-bottom: 10px;
    }
    
`;

export const UnderMaintenance = ()=>{
    
    return (
        <BODY>
            <main>
                <div id="container">
                    <div>
                        <div id="img-container">
                            <img src={mainLogo} />
                        </div>
                    </div>

                    <div>
                        <div>
                            <div id="underlined-header">
                                <h1>We will be back in a bit</h1>
                            </div>
                        </div>
                    </div>


                    <div id="notice">
                        <div>
                            <div className="flex justify-center">
                                <div>
                                    <div>
                                        <div>
                                            <h2>Sorry, this site is currently under maintenance</h2>
                                            <p className="p1">
                                                This system is currently undergoing routine maintenance to ensure you get the best experience.
                                                <br/>
                                                The maintenance is expected to continue for a few hours at most. Please check back later.
                                            </p>
                                            <p className="p1">Thank you for your understanding!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </main>

            <footer>
                <div>

                    <div id="f1">
                        <div>
                            <div className="f2">Vendors Portal © Best Gift Group 1996 - 2024</div>
                            <div className="f2">For support contact <a href="mailto:vendors@bestgiftgroup.com">vendors@bestgiftgroup.com</a></div>
                        </div>
                    </div>
                </div>
            </footer>
        </BODY>
    );
};

import { useContext, useEffect, useRef, useState } from "react";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CoreServiceSelector } from "../../services/core/service-selector";
import { getVoucherError, isInvalidVoucher } from "../../library/core/messages";
import { ButtonsPanel } from "../common/buttons-panel";
import { InvalidInputMessage } from "../form/invalid-input";
import { ToggleView } from "../views/toggle-view";
import styled from "styled-components";
import { LoadingOverlay } from "../layout/loading-overlay";

const Form = styled.form`
    min-height: 300px;
`;

const InputWithButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    input { margin-right: 8px; }
    button {min-width: 180px;}
`;

const voucherField =  {
    name: 'barcode',
    label: "Card/Certificate number",
    hint: 'Gift card or gift certificate number',
    type: 'text',
    required: true,
    isRequiredMessage: "Card/certificate number can't be empty",
    validate: (value, dataModel)=> {
        if(!value || value.length < 10)
            return `Invalid card/certificate number`;
        return null;
    }
};


export const PullVoucherType = ({
    dataModel,
    buttonLabel,
    serviceDispatcher, 
    onSetVoucher,
    otherPanels,
    otherButtons
})=>{
    dataModel ||= {};
    const {state, dispatch} = useContext(CognitoContext);
    const voucherNumberRef = useRef();
    const [model, setModel] = useState({});
    const [formState, setFormState] = useState({});
    const [errors, setErrors] = useState({});
    buttonLabel ||= "Next";

    useEffect(()=>{
        voucherNumberRef.current.focus();
    });
    const onChangeHandler = (event)=>{
        setModel({
            ...model,
            [event.target.name]: GetEventInputValue(event, true)
        });
    };

    const findVoucher = async ()=>{
        let service = null;
        if(serviceDispatcher && typeof serviceDispatcher === 'function')
            service = await serviceDispatcher();
        else
            service = CoreServiceSelector.GetRedemptionService(state.session);
        
        setFormState({
            ...formState,
            isProcessing: true
        });
        service.getVoucherInfo(model.barcode, dataModel.companyId)
            .then((data)=>{
                setFormState({
                    ...formState,
                    isProcessing: false
                });
                
                if(isInvalidVoucher(data))
                {
                    setErrors({
                        barcode: getVoucherError(data)
                    });
                    return;
                }
                
                onSetVoucher && onSetVoucher(data);

            }).catch(err=>{
                setFormState({
                    ...formState,
                    isProcessing: false
                });
                
                setErrors({
                    barcode: 'Something wrong happened, please try later'
                })
            });
    };

    const onSubmit =  (event)=>{
        event.preventDefault();
        
        const errs = ValidateFormView({fields:[voucherField], model});
        setErrors(errs);
       
        if(!errs || Object.keys(errs).length === 0)
        {
            findVoucher();
        }
    };
    const errorMessage = errors && errors.barcode;
    const value = model.barcode || '';
    const hintId = `${voucherField.name}Hint`;
    return (
        <div>
            <LoadingOverlay
                    isLoading={formState.isProcessing} 
                    title="Please wait ..."
                    message="Loading data in progress"
            >

                <Form>
                    <div>
                        <div className="mb-3">
                            <label htmlFor={voucherField.name} className="form-label">{voucherField.label}</label>
                            <InputWithButtonDiv>
                                <input ref={voucherNumberRef}
                                    required={voucherField.required}
                                    type="text"
                                    className={"form-control" + (errorMessage?" is-invalid":"")}
                                    name={voucherField.name} 
                                    id={voucherField.name} 
                                    aria-describedby={hintId}
                                    value={value}
                                    autoComplete="off"
                                    onChange={onChangeHandler}
                                />
                                <button type="submit" className="btn btn-primary" onClick={onSubmit}>{buttonLabel}</button>
                            </InputWithButtonDiv>
                            <InvalidInputMessage errorMessage={errorMessage}/>
                            <ToggleView show={voucherField.hint}>
                                <div id={hintId} className="form-text">{voucherField.hint}</div>
                            </ToggleView>
                                
                        </div>
                        
                    </div>
                    
                    <div>
                        {otherPanels}
                    </div>

                    <ButtonsPanel>
                        {otherButtons}
                    </ButtonsPanel>
                </Form>

            </LoadingOverlay>
        </div>
    );
};


import styled from "styled-components";
import { Alert, Alerts } from "../bootstrap/alert";

export const InfoDiv = styled.div`
    font-size: 1.1rem;
`;

const Icon = styled.i`
    font-size: 19px;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    font-weight: 800;
    color: #dc3545;
`;


export const CardNumberBox = ({label, cardNumber})=>{
    label ||= "The gift card you entered is";
    return ( 
    <Alert type={Alerts.secondary} hide={false} style={{marginTop:-14}}>
        <InfoDiv>
            <span>{label} <strong>{cardNumber}</strong></span>
            {/* <Icon className="bi bi-x-circle"></Icon> */}
        </InfoDiv> 
    </Alert>);
};


export const UnauthorisedPerson = ({show})=>{
    if(!show)
        return null;
    return (
        <div>
            <div className="alert alert-danger">
                Sorry! You are not authorised for this transaction, please contact the administrator.
            </div>
        </div>
    );
};
import styled from "styled-components";

const ContainerDiv = styled.div`
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
`;

export const InvalidInputMessage = ({errorMessage})=>{
    if(!errorMessage)
        return null;
    return (
        <ContainerDiv>
            {errorMessage}
        </ContainerDiv>
    );
};

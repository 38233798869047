const Alerts = {
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    danger: 'danger',
    warning: 'warning',
    info: 'info',
    light: 'light',
    dark: 'dark'
};

const Alert = ({type, hide, children, style})=>{
    if(hide)
        return null;
        
    const className = `alert alert-${type || 'primary'}`;

    return (
        <div className={className} role="alert" style={style}>
            {children}
        </div>
    );
};

export {Alert, Alerts};


import styled from "styled-components";
import { FormatDecimal } from "../../library/core/formats";
import { FormInput } from "../form/input";
import { GetEventInputValue } from "../form/core";
import { useState } from "react";

const SpanBlock = styled.span`
    display: inline-block;
    padding: 0 3px;
`;

const TotalBillCaption = styled.div`
    font-size: 1rem;
`;

const SuccessMessage = ({
    totalBillAmount, 
    totalRedeemed, 
    digits
})=>{
    digits = digits || 2;
    totalRedeemed ||= 0;
    if(totalRedeemed === 0)
    {
        return (
            <TotalBillCaption>
                Balance to collect is <SpanBlock>${FormatDecimal(totalBillAmount, digits)}</SpanBlock> 
            </TotalBillCaption>
        );
    }
    else if(totalRedeemed >= totalBillAmount)
        return (
            <TotalBillCaption>
                Successfully redeemed total amount of <SpanBlock>${FormatDecimal(totalRedeemed, digits)}</SpanBlock>
            </TotalBillCaption>
        );
    else
        return (
            <TotalBillCaption>
            <SpanBlock> ${FormatDecimal(totalRedeemed, digits)}  </SpanBlock>successfully redeemed. 
            Balance to collect is <SpanBlock>${FormatDecimal(totalBillAmount-totalRedeemed, digits)}</SpanBlock> 
            </TotalBillCaption>
        );
};




const totalBillField =  {
    name: 'totalBillAmount',
    label: "Customer's total bill amount",
    hint: 'Total amount for customer to pay - next screens will guide you through redemption process.',
    type: 'number',
    required: true,
    validate: (value, dataModel)=> {
        if(value <= 0 || value < dataModel.amount)
            return `Total bill amount should be greater than redeemed amount`;
        return null;
    }
};



export const CaptureTotalBillAmount = ({
        onSetTotalBillAmount
    })=>
{
    const [model, setModel] = useState({});
    const [errors, setErrors] = useState({});

    const onChangeHandler = (event)=>{
        setModel({
            ...model,
            [event.target.name]: GetEventInputValue(event, true)
        });
    };

    const onSubmit = ()=> {
        onSetTotalBillAmount && onSetTotalBillAmount({totalBillAmount : parseFloat(model.totalBillAmount)});
    };

    return (
        <div>
           <>
                <FormInput
                    key={totalBillField.name} 
                    dataModel={model} 
                    {...totalBillField}
                    errorsModel={errors}
                    onChange={onChangeHandler}
                />
                <button type="submit" className="btn btn-primary" onClick={onSubmit}>Next</button>
            </>
        </div>
    );

};


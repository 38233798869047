import { useRef, useState } from "react";
import { PageContainer } from "../layout/page-container";
import { useNavigate } from "react-router-dom";
import { AppRouteNames } from "../../routes/app-routes";
import { ToggleView } from "../views/toggle-view";
import { Alert, Alerts } from "../bootstrap/alert";
import { LoadingOverlay } from "../layout/loading-overlay";
import { FormInput } from "../form/input";
import { GetEventInputValue } from "../form/core";
import styled from "styled-components";
import { NoLoginService } from "../../services/core/no-login-service";

const formView = {
    fields:[
        {
            name: "passCode",
            label: "Best Vendor Redeemer ID",
            hint: "Use your provided Best Vendor Redeemer ID to redeem a card without login.",
            required: true,
            validate: (value, dataModel)=> {
                if(value.length < 6)
                    return `Best Vendor Redeemer ID should be at least 6 digits`;
                return null;
            }
        }
    ]
};

export const ButtonsBar = styled.div`
    button {
        margin-right: 10px;
    }
`;

export const ExpressLogin = ({onLoginSuccess, getRecaptchaToken})=> {
    const [model, setModel] = useState({});
    const [errors, setErrors] = useState({});
    const[formState,setFormState]=useState({errorMessage:"",isSubmitted:false})
    const [loader, setLoader] = useState(false);


    const navigate = useNavigate();
   
    const onCancelHandler = ()=>{
        navigate(AppRouteNames.signin);
    };

    
    const onChangeHandler = (event)=>{
        setFormState({errorMessage:"",isSubmitted:false})
        setModel({
            ...model,
            [event.target.name]: GetEventInputValue(event, true)
        });
    };

    const onLoginHandler = async (event) => {
        event.preventDefault();
        const captcha = await getRecaptchaToken(true);
        setLoader(true)
        const service = new NoLoginService({ passCode: model.passCode, captcha });
        const userInfo = await service.getUserInfo().catch((err) => {
            setFormState({ errorMessage: "Enter valid Redeemer Id", isSubmitted: true })
            setLoader(false)
        });

        userInfo && onLoginSuccess && onLoginSuccess({
            userInfo,
            passCode: model.passCode
        });
        setLoader(false)
    };

    return (
        <div style={{height:"100vh"}}>
        <LoadingOverlay isLoading={loader}
        title="Please wait ..."
        message="Loading Page">
        <PageContainer>           
            <ToggleView show={formState.isSubmitted}>
                        <Alert type={Alerts.danger} hide={formState.isSuccess}>
                        {formState.errorMessage}
                    </Alert>
                </ToggleView>
                <LoadingOverlay 
                    isLoading={formState.isProcessing} 
                    title="Please wait ..."
                    message="Card redemption in progress"
                    >                       
                        <form>
                        {
                            formView.fields.map(field => <FormInput
                                                            key={field.name} 
                                                            dataModel={model} 
                                                            {...field}
                                                            errorsModel={errors}
                                                            onChange={onChangeHandler}
                                                            />
                                                )
                        }
                        <ButtonsBar>
                            <button className="btn btn-primary" onClick={onLoginHandler}>Proceed</button>
                            <button className="btn btn-secondary" onClick={onCancelHandler}>Cancel</button>
                        </ButtonsBar>
                    </form>                                       
                    </LoadingOverlay>

        </PageContainer>
        </LoadingOverlay>
        </div>
    );
};

import { useContext, useState } from "react";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CognitoConfirmSignup, CognitoGetErrorMessage, CognitoResetPassword, ResendConfirmCode } from "../../library/cognito/confirm";
import { CurrentUserStorage } from "../../library/core/current-user";
import { Alert, Alerts } from "../bootstrap/alert";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { FormInput } from "../form/input";
import { isValidPassword, PASSWORD_HINT } from "../../library/core/utils";



const formView = {
  fields:[
      {
          name: "username",
          label: "Email address",
          type: 'email',
          required: true
      },
      {
          name: "verifyCode",
          label: "Verification code",
          type: 'text',
          required: true
      },
      {
          name: 'password',
          label: 'New Password',
          type: 'password',
          //hint: PASSWORD_HINT,
          required: true,
          validate: (value)=>{
            if(!isValidPassword(value))
              return PASSWORD_HINT;
            return null;
          }
      },
      {
        name: 'confirmPassword',
        label: 'Confirm password',
        type: 'password',
        required: true,
        validate: (value, dataModel)=> {
            if(value !== dataModel.password)
                return `this field does not match password!`;
            return null;
        }
      }
  ]
};



export const ResetPasswordForm = ({
  username,
  errorMessage,
  onSuccess,
  onCancel,
  onError
}) => {
    
    username = username || CurrentUserStorage.getCurrentUser();
    const [model, setModel] = useState({username});
    const [modelErrors, setModelErrors] = useState({}); 
    const cognitoContext = useContext(CognitoContext);

    const raiseError = (err)=> {
      onError && onError(err);
      return false;
    };

    const validateForm = ()=>{
      if(!model.password || model.password.trim().length === 0)
      {
          return raiseError('password cat be empty');
      }

      if(!model.confirmPassword || model.confirmPassword.trim().length === 0)
      {
          return raiseError('password cat be empty');
      }

      if(model.password !== model.confirmPassword)
        return raiseError('passwords does not match');


      return true;
    };

    const onConfirmHandler = (event)=>{
      event.preventDefault();

      
    const errs = ValidateFormView({fields: formView.fields, model});
    setModelErrors(errs);

    if(!errs || Object.keys(errs).length === 0)
    {
        CognitoResetPassword({
          cognitoContext: cognitoContext.state,
          username: model.username,
          verifyCode: model.verifyCode,
          password: model.password,
          onSuccess: ()=>{
            onSuccess && onSuccess({
              username: model.username,
              password: model.password
            });
          },
          onError: (err)=>{
            onError && onError(err);
          }
        });
    }
     

    };

    const resendCodeHandler=(event)=>{
      event.preventDefault();
      ResendConfirmCode({ 
        cognitoContext: cognitoContext.state,
        username: model.username,
        onError});   
    };

  const onFieldChangeHandler = (event) => {
   
     setModel({
         ...model,
         [event.target.name]: GetEventInputValue(event)  
       });
  };

  return (
      <form>
        <h3>Reset your password</h3>
        <div>
          <p style={{marginBottom:"0.2rem"}}>
            If the email address you entered matches an existing account, 
            please check your email for a verification code to reset your password.
          </p>
        </div>
        <Alert type={Alerts.danger} hide={!errorMessage}>
          <p>{errorMessage}</p>
        </Alert>

        {
            formView.fields.map(field => <FormInput 
                                            key={field.name} 
                                            dataModel={model} 
                                            {...field}
                                            errorsModel={modelErrors}
                                            onChange={onFieldChangeHandler}
                                            />
                                )
        }

       

        <div className="d-grid">
          <button type="submit" className="btn btn-primary" onClick={onConfirmHandler}>
            Update Password
          </button>
        </div>

        <div className="d-grid" style={{
          margin: "10px 0"
        }}>
          <button type="button" className="btn btn-secondary" onClick={onCancel}>
            Cancel
          </button>
        </div>
        
        
      </form>
    );
};

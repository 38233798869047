import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CognitoContext, UserProviderActions } from "../../contexts/cognito/cognito-provider";
import { CoreServiceSelector } from "../../services/core/service-selector";
import { MenuItems } from "./menu-items";
import { AppRouteNames } from "../../routes/app-routes";
import { useNavigate } from "react-router-dom";


const OffCanvas = styled.div`
    background-color: white;
    @media print { display: none;}
`;

const CanvasHeader = styled.h5`
    background-color: var( --theme-background-color);
    color:white;
    width: 100%;
    padding: 5px 15px;
    margin-bottom: 1px;
`;


export const PortalSideMenu = ({menu, asSideMenu, asTopMenu})=>{
    const [Menu, setMenu] = useState(menu);
    const {state, dispatch} = useContext(CognitoContext);
    const navigate = useNavigate();

    const navigateToLogin = ()=>{
        dispatch({
            type: UserProviderActions.setSession, 
            session: null
        });
        navigate(AppRouteNames.signin)
    };

    const getUserInfo = async ()=>{
        if(!state.session)
            return;

        const userInfoService = CoreServiceSelector.GetUserInfoService(state.session);
        userInfoService.setUnauthorisedHandler(navigateToLogin);

        const menuData =  await userInfoService.getUserMenu().catch(err=>{
            console.log(err);
        });
        setMenu(menuData);

        if(!userInfoService.getSavedUserInfo() || !state.userInfo)
        {
            const userInfo =  await userInfoService.getUserInfo().catch(err=>{
                console.log('quit from side menu', err);
                //navigateToLogin();
            });
            dispatch({type: UserProviderActions.setUserInfo, userInfo});
        }
    };

    useEffect(()=>{
       getUserInfo();
    },[state]);

    if(!Menu)
        return null;

    if(asSideMenu)
    {
        return (
            <OffCanvas 
                className="offcanvas.show offcanvas-start" 
                tabIndex="-1" id="offcanvasExample" 
                aria-labelledby="offcanvas-title"
            >
                <div className="offcanvas-header">
                    <CanvasHeader 
                        className="offcanvas-title" 
                        id="offcanvas-title"
                    >
                        {Menu.title || 'Main Menu'}
                    </CanvasHeader>
                </div>

                <div className="offcanvas-body">
                    <MenuItems items={Menu.items} asSideMenu={true}/>
                </div>
            </OffCanvas>
        );
    }

    if(asTopMenu)
    {
        return (<MenuItems items={Menu.items} asTopMenu={true}/>);
    }
};

import { useContext, useEffect, useState } from "react";
import { DataTable } from "../../components/bootstrap/data-table";
import { LoadingOverlay } from "../../components/layout/loading-overlay";
import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { AuthorisedPage } from "../auth/authorised-page";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PortalUserForm } from "../../components/users/edit-user";
import { CoreServiceSelector } from "../../services/core/service-selector";
import { AppRouteNames } from "../../routes/app-routes";
import { ToggleView } from "../../components/views/toggle-view";
import { Alert, Alerts } from "../../components/bootstrap/alert";



export const AddUserPage = ()=>{
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [pageState, setPageState] = useState({
        isLoading: true
    });
    const [unauthorised, setUnauthorised] = useState({show: false});
    const {state, dispatch} = useContext(CognitoContext);

   

    useEffect(()=>{
        setPageState({isLoading: false});
    },[]);


    
    const onSaveUser = async (model)=>{
        setPageState({
            ...pageState,
            isLoading: true,
            message: "Creating new user"
        });

        const service = CoreServiceSelector.GetUserManagementService(state.session);
        if(!service)
            return;
        
        const result =  await service
                                    .saveUserRecord(model)
                                    .catch((err)=>{
                                        console.log(err);
                                        if(err.response.status === 401){
                                            setUnauthorised({show: true});
                                        }
                                        
                                        setData({...model});

                                        setPageState({
                                            isLoading: false,
                                            hasErrors: true,
                                            errorMessage: err.response.data || "Something wrong happened"
                                        });
                                    });
        
        
        if(result)
        {
            setData({});
            navigate(`${AppRouteNames.usersList}`);
        }

    };


    return (
        <AuthorisedPage>
            <PortalMainLayout title="Add New User" isUnauthorised={unauthorised.show}>
                <ToggleView show={pageState.hasErrors}>
                    <Alert type={Alerts.danger}><strong>Oops!</strong><br></br>{pageState.errorMessage}</Alert>
                </ToggleView>

                <LoadingOverlay
                    isLoading={pageState.isLoading} 
                    title="Please wait ..."
                    message={pageState.message}
                >
                    <PortalUserForm isNew={true} data={data} onSave={onSaveUser}/>
                </LoadingOverlay>
               
            </PortalMainLayout>
        </AuthorisedPage>
    );
};



import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';



const getAttrs = ({cognitoUser, onSuccess, onError})=>{
    
    cognitoUser.getUserAttributes(function(err, attributes) {
        if (err) {
            onError && onError(err);
        } else {
            onSuccess && onSuccess(attributes);
        }
    });

    
};

const noStorageService = {
    setItem: (key, value)=>{},
    getItem: (key)=> null,
    removeItem: (key)=>{},
    clear: ()=>{}
};

const sessionStorage = null;
// TODO: research how to save session in a secure cookie
// new CookieStorage({
//     domain: process.env.REACT_APP_COOKIE_DOMAIN
// });

const CognitoSignin = ({
    cognitoContext, 
    username, 
    password,
    keepMeLoggedIn,
    onSuccess,
    onError,
    onResetPassword,
    onUserNotConfirmed,
    onUserRemoved,
    onForceChangePassword
})=>{
    

    var authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password
    });
    
    var cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoContext.userPool,
        Storage: keepMeLoggedIn? sessionStorage : noStorageService
    });

    const onSuccessHandler = function(result) {
        const accessToken = result.getAccessToken().getJwtToken();
        const jwtToken = result
            .getIdToken()
            .getJwtToken();
        
        getAttrs({
            cognitoUser,
            onSuccess: (attributes)=>{
                onSuccess && onSuccess({
                    username,
                    accessToken,
                    jwtToken,
                    attributes
                });
            },
            onError
        });
    };

    const onFailureHandler = function(err){
        if(err && err.code === 'PasswordResetRequiredException')
        {
            onResetPassword && onResetPassword();
        }
        else if(err && err.code === 'UserNotConfirmedException')
        {
            onUserNotConfirmed && onUserNotConfirmed();
        }
        else if(err && err.code === 'UserNotFoundException')
        {
            onUserRemoved && onUserRemoved();
        }
        else
        {
            
            onError && onError(err);
        }
    };
   

    cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: function(userAttributes){
            onForceChangePassword && onForceChangePassword({
                username,
                completeNewPasswordHandler: ({newPassword, phoneNumber, onSuccess})=>{
                    cognitoUser.completeNewPasswordChallenge(
                        newPassword, 
                        {
                            "phone_number": phoneNumber
                        },
                        {
                            onSuccess,
                            onFailure: onError
                        }
                    )
                }
            });
        },

        onSuccess: onSuccessHandler,
    
        onFailure: onFailureHandler
    });
};

export  {CognitoSignin};

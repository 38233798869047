import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CognitoContext, UserProviderActions } from "../../contexts/cognito/cognito-provider";
import { AppRouteNames } from "../../routes/app-routes";
import { CurrentUserStorage } from "../../library/core/current-user";



const getUserSession = (session, attrs)=>{
    const {payload} = session.idToken;

    const companyItems = attrs.filter(attr => attr.Name === 'custom:company').map(attr=> attr.Value);
    
    return {
        instance: session,
        authTime: new Date(1000 * payload.auth_time),
        expTime: new Date(1000 * payload.exp),
        email: payload.email,
        fullName: payload.name,
        companyName: companyItems.length > 0 ? companyItems[0] : '',
        phone: payload.phone_number,
        attrs
    };
};


const AuthorisedPage = ({children})=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {state, dispatch} = useContext(CognitoContext);

    const targetUrl = encodeURI(`${location.pathname}${location.search}`);
    const signinPath = `${AppRouteNames.signin}?url=${targetUrl}`;
    
    // state.cognitoUser.signOut(()=>{
    //     dispatch({type: UserProviderActions.signout});
    //     navigate(AppRouteNames.signin)
    // });
    
    

    const navigateToLogin = ()=>{
        CurrentUserStorage.clearSession();
        dispatch({
            type: UserProviderActions.setSession, 
            session: null
        });
        navigate(signinPath);
    };

    
   
    useEffect(()=>{
        var cognitoUser = state.userPool.getCurrentUser();
        
        if(cognitoUser === null)
        {
            navigateToLogin();
            return;
        }

        dispatch({
            type: UserProviderActions.setCognitoUser, 
            cognitoUser
        });

        if(cognitoUser !== null)
        {
            
            cognitoUser.getSession((err, session)=>{
                
                if(err || !session.isValid()){
                    navigateToLogin();
                    return;
                }
                
            
                cognitoUser.getUserAttributes(function(err, attributes) {
                   
                    if (!err) {
                        const userSession = getUserSession(session, attributes);
                        
                        dispatch({
                            type: UserProviderActions.setSession, 
                            session: userSession
                        });

                    }
                    else{
                        navigateToLogin();
                    }
                });
            

                
            });
            
            
        }
        
    },[]);
    
    return children;
};

export {AuthorisedPage};
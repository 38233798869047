import styled from "styled-components";

const AuthInnerDiv = styled.div`
    width: 100%;
    max-width: 315px;
    padding: 40px 15px 45px 15px;
    @media (min-width: 425px){
        max-width: 400px;
        padding: 40px 40px;
    }
    @media (min-width: 456px){
        max-width: 450px;
        padding: 45px 40px;
    }
`;

const AuthContainer = ({children})=>{
    return (
        <div className="auth-body">
            <div className="auth-wrapper">
                <AuthInnerDiv className="auth-inner">
                    {children}
                </AuthInnerDiv>
            </div>
        </div>

    );
};

export {AuthContainer};
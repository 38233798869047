import { useContext, useState } from "react";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CognitoConfirmSignup, ResendConfirmCode } from "../../library/cognito/confirm";
import { CurrentUserStorage } from "../../library/core/current-user";
import { GetEventInputValue } from "../form/core";
import { LoadingOverlay } from "../layout/loading-overlay";
import { Link, useNavigate } from "react-router-dom";
import { AppRouteNames } from "../../routes/app-routes";


export const VerifyCodeForm = ({
  username,
  onSuccess,
  onError
}) => {
  username = username || CurrentUserStorage.getCurrentUser();
  
  const navigate = useNavigate();
  const [model, setModel] = useState({username});
  const cognitoContext = useContext(CognitoContext);
  const [formState, setFormState] = useState({
    isLoading: false
  });

  const setIsLoading = (isLoading)=> setFormState({...formState, isLoading});

  const onSuccessHandler = (data)=>{
    setIsLoading(false);
    onSuccess && onSuccess(data);
  };

  const onErrorHandler = (err)=>{
    setIsLoading(false);
    onError && onError(err);
  };

  const onConfirmHandler = (event)=>{
     event.preventDefault();
     setIsLoading(true);

     CognitoConfirmSignup({
          cognitoContext: cognitoContext.state,
          username: model.username,
          verifyCode: model.verifyCode,
          onSuccess: onSuccessHandler,
          onError: onErrorHandler
      });
  };

  const resendCodeHandler=(event)=>{
    event.preventDefault();
    setIsLoading(true);

    ResendConfirmCode({ 
      cognitoContext: cognitoContext.state,
      username: model.username,
      onSuccess: ()=> setIsLoading(false),
      onError: onErrorHandler
    });   
  };

  const onFieldChangeHandler = (event) => {
   
     setModel({
         ...model,
         [event.target.name]: GetEventInputValue(event)  
       });
  };


  
  const navigateToLogin = (event)=>{
    event.preventDefault();
    window.location = AppRouteNames.signin;
    window.location.reload();
};


  return (
      <LoadingOverlay isLoading={formState.isLoading} title="Please wait ...">
        <form>
          <h3>Verify your account</h3>
          
          <div className="mb-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Your email address"
              id="username"
              name="username"
              value={model.username || ''}
              onChange={onFieldChangeHandler}
            />
          </div>

          <div className="mb-3">
            <label>Verification Code</label>
            <input
              type="text"
              className="form-control"
              id="verifyCode"
              name="verifyCode"
              value={model.verifyCode || ''}
              placeholder="Enter verification code"
              onChange={onFieldChangeHandler}
            />
          </div>


          <div className="d-grid">
            <button type="submit" className="btn btn-primary" onClick={onConfirmHandler}>
              Verify Account
            </button>
          </div>

          <div className="d-grid" style={{
            margin: "10px 0"
          }}>
            <button type="button" className="btn btn-secondary" onClick={navigateToLogin}>
              Cancel
            </button>
          </div>

          <p className="forgot-password text-right" style={{textAlign: "left"}}>
            Didn't receive it? Check your spam folder or <a onClick={resendCodeHandler} href="#">resend the code</a>
          </p>
          {/* <p className="forgot-password" style={{textAlign: "left"}}>
            Or click <a onClick={navigateToLogin} href="#">here</a> to sign in with a different account
          </p> */}
        </form>
      </LoadingOverlay>
    );
};


import { CoreService } from "./core-service";
import { add, compareAsc, parseISO } from 'date-fns';
import { getMenuItems, isInUserRoles } from "./user-menu-items";

const USER_INFO_LOCAL_STORAGE_ITEM = 'USER_INFO_LOCAL_STORAGE_ITEM';
const EXPIRE_SESSION_AFTER_MINUTES = 60;

const isExpiredState = (state)=>{
    return (!state || !state.expiryDate || compareAsc(new Date(), parseISO(state.expiryDate)) > 0);
};

export class UserInfoService extends CoreService
{
    constructor(session){
        super(session);
    }
    
   
    getUserMenuUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api/user-info/user-menu`;
    getUserInfoUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api/user-info`;

    hasExpiredInfo = ()=> {
        const data = localStorage.getItem(USER_INFO_LOCAL_STORAGE_ITEM);
        if(!data)
            return true;

        let state = JSON.parse(data);
        return isExpiredState(state);
    }

    getState = ()=> {
        const data = localStorage.getItem(USER_INFO_LOCAL_STORAGE_ITEM);
        
        if(data){
            let state = JSON.parse(data);
            if(isExpiredState(state)){
                return this.getDefaultState();
            }
                
            return state;
        }

        return this.getDefaultState();
    }

    saveState = (state)=>{
        localStorage.setItem(USER_INFO_LOCAL_STORAGE_ITEM, JSON.stringify(state));
    }

    getDefaultState = ()=>{
        return {
            expiryDate: add(new Date(),{
                minutes: EXPIRE_SESSION_AFTER_MINUTES
            })
        };
    }

    getSavedMenu = ()=>{
        const state = this.getState();
        return state.userMenu;
    }

    getSavedUserInfo = ()=>{
        const state = this.getState();
        return state.userInfo;
    }

    saveMenu = (userMenu)=>{
       const state = this.getState();
       state.userMenu = userMenu;
       this.saveState(state);
    }

    _old_getUserMenu = async (forceRefresh)=>{
        if(!this.session)   //TODO: also check if session is valid
            return null;

        const menu = this.getSavedMenu();
        if(!forceRefresh && menu)
            return menu;

        const r = await this.callWebApi('GET', this.getUserMenuUrl(), null);
        this.saveMenu(r.data);
        return r.data;
    }

    getUserMenu = async (forceRefresh)=>{
        if(!this.session)   //TODO: also check if session is valid
            return null;

        const userInfo =  await this.getUserInfo(forceRefresh);

        const menuItems = [
            {
                roles: 'redeem,history',
                title: 'Redemption',
                icon: 'bi-credit-card-2-back',
                items: getMenuItems({
                    category: 'main',
                    userRoles: userInfo.UserRoles
                })
            },
            {
                roles: 'useradmin',
                title: 'User Management',
                icon: 'bi-person-fill-gear',
                items: getMenuItems({
                    category: 'useradmin',
                    userRoles: userInfo.UserRoles
                })
            }
        ];
        return {
            title: 'Main Menu',
            items:menuItems.filter(item=> isInUserRoles( item.roles, userInfo.UserRoles))
        };
        
    }
   
    getUserInfo = async (forceRefresh)=>{
        
        
        if(!this.session)   //TODO: also check if session is valid
            return null;

        const state = this.getState();
        if(!forceRefresh && state && state.userInfo)
            return state.userInfo;

        const r = await this.callWebApi('GET', this.getUserInfoUrl(), null);

        state.userInfo = r.data;
        this.saveState(state);

        return r.data;
    }
} 

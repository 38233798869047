import { DevRedemptionService } from "../dev/redemption-service";
import { DevSignupInfoService } from "../dev/signup-info-service";
import { DevUserInfoService } from "../dev/user-info-service";
import { DevUserManagementService } from "../dev/user-management-service";
import { MockRedemptionService } from "../mock/redemption-service";
import { MockSignupInfoService } from "../mock/signup-info-service";
import { MockUserInfoService } from "../mock/user-info-service";
import { MockUserManagementService } from "../mock/user-management-service";
import { ProdRedemptionService } from "../prod/redemption-service";
import { ProdSignupInfoService } from "../prod/signup-info-service";
import { ProdUserInfoService } from "../prod/user-info-service";
import { ProdUserManagementService } from "../prod/user-management-service";

const PortalModes = {
    dev:{
        name: 'dev',
        nav: '(DEV)',
    },
    mock:{
        name: 'mock',
        nav: '(MOCK)',
    },
    prod:{
        name: 'prod',
        nav: '',
    }
};


export const GetEnvironmentTag = ()=>{
    let tag = 'dev';

    if(process.env.REACT_APP_DEV_MODE.toLowerCase() === 'dev')
        tag = 'dev';
    else if(process.env.REACT_APP_DEV_MODE.toLowerCase() === 'mock')
        tag = 'mock';
    else if(process.env.NODE_ENV.toLowerCase() === "production")
        tag = 'prod';

    return tag;
};

export const GetEnvironmentNavLabel = ()=>{
    const tag = GetEnvironmentTag();
    return PortalModes[tag].nav;
};

export const ServiceOf = (props)=>{
    const {mock, dev, prod} = props;
    const tag = GetEnvironmentTag();
    return props[tag];
};


export const CoreServiceSelector = {
    GetUserInfoService: (session)=>{
        if(!session)
            return null;
    
         return ServiceOf({
            mock: new MockUserInfoService(session),
            dev: new DevUserInfoService(session),
            prod: new ProdUserInfoService(session)
        });
    },

    GetUserManagementService: (session)=>{
        if(!session)
            return null;
    
         return ServiceOf({
            mock: new MockUserManagementService(session),
            dev: new DevUserManagementService(session),
            prod: new ProdUserManagementService(session)
        });
    },

    GetSignupInfoService: (session)=>{
         return ServiceOf({
            mock: new MockSignupInfoService(session),
            dev: new DevSignupInfoService(session),
            prod: new ProdSignupInfoService(session)
        });
    },


    GetRedemptionService : (session)=>{
        if(!session)
            return null;
    
         return ServiceOf({
            mock: new MockRedemptionService(session),
            dev: new DevRedemptionService(session),
            prod: new ProdRedemptionService(session)
        });
    }
};


import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { AuthorisedPage } from "../auth/authorised-page";

const DashboardPage = ()=>{
    
    return (
        <AuthorisedPage>
            <PortalMainLayout title="Dashboard">
                <div>
                    This is your dash board
                </div>
            </PortalMainLayout>
        </AuthorisedPage>
    );
};

export {DashboardPage};
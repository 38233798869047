import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CognitoContext, UserProviderActions } from "../../contexts/cognito/cognito-provider";
import { AppRouteNames } from "../../routes/app-routes";
import { PortalSideMenu } from "../side-menu";
import { DesktopSpan, MobileSpan } from "../common/toggled-span";
import { GetEnvironmentNavLabel } from "../../services/core/service-selector";
import styled from "styled-components";

const dropDownStyle={
  left: 'auto'
};

const Span = styled.span`
  display: inline-block;
  margin-left: 15px;
`;


const BoldSpan = styled.span`
  font-weight: bold;
`;

const BurgerIcon = styled.i`
  color: white;
  font-size: 20px;
`;


const DropDownToggle = styled.a`
  &.show{
    display: none;
  }
  
`;

const NavUserDropMenu = ({isLoggedIn, isMobile})=>{
  const {state, dispatch} = useContext(CognitoContext);
  const navigate = useNavigate();
  
  if(!state.session || !state.cognitoUser)
    return null;
  
  const companyName = (state.userInfo && state.userInfo.CompanyName) || 'loading ...';

  const signoutHandler = (event)=>{
    event.preventDefault();
    state.cognitoUser.signOut(()=>{
      dispatch({type: UserProviderActions.signout});
      navigate(AppRouteNames.signin)
    });
  };

 
  return (
    <div id="navbar-dropdown" className={`navbar-nav${isMobile?"":" justify-content-end"}`}>
        <DropDownToggle className="nav-link dropdown-toggle" href="#navbar-dropdown" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <DesktopSpan><i className="bi bi-person-circle"></i> {state.session.fullName} ({companyName})</DesktopSpan>
          <MobileSpan><BurgerIcon className="bi bi-list"></BurgerIcon> {isMobile?(<span>Vendors Portal {GetEnvironmentNavLabel()}</span>):null}</MobileSpan>
        </DropDownToggle>
        <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style={dropDownStyle}>
          {/* <li><a className="dropdown-item" href="#">Profile <i className="bi bi-person-lines-fill"></i></a></li> */}
          {/* <li><hr className="dropdown-divider"/></li> */}
          {
            isLoggedIn?(<PortalSideMenu asTopMenu={true}/>):null
          }
          <li>
            <a className="dropdown-item" href="#navbar-dropdown" onClick={signoutHandler}>
              <>Log Out 
              <BoldSpan>{isMobile?` ${state.session.fullName} (${companyName})`:null}</BoldSpan>
              <Span><i className="bi bi-box-arrow-right"></i></Span></>
            </a>
          </li>
        </ul>
    </div>
  );
};



export {NavUserDropMenu};
import { CognitoUser } from "amazon-cognito-identity-js";

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContainer } from "../../components/auth/container";
import { LoginForm } from "../../components/auth/login.form";
import { CognitoContext, UserProviderActions } from "../../contexts/cognito/cognito-provider";
import { CognitoSignin } from "../../library/cognito/signin";
import {useSearchParams} from 'react-router-dom';
import { AppRouteNames } from "../../routes/app-routes";
import { CurrentUserStorage } from "../../library/core/current-user";
import { CoreServiceSelector } from "../../services/core/service-selector";
import { ResetPasswordForm } from "../../components/auth/reset-password";
import { ToggleView } from "../../components/views/toggle-view";
import { CognitoGetErrorMessage } from "../../library/cognito/confirm";
import { ForgotPasswordForm } from "../../components/auth/forgot-password";
import { LoadingOverlay } from "../../components/layout/loading-overlay";
import { ForceResetPasswordForm } from "../../components/auth/force-reset-password";
import { VerifyCodeForm } from "../../components/auth/verify-account.form";
import { Alert, Alerts } from "../../components/bootstrap/alert";

const Modes = {
    normal: 'normal',
    resetPwd: 'resetPwd',
    userNotConfirmed: 'userNotConfirmed',
    userRemoved: 'userRemoved',
    forgotPwd: 'forgotPwd',
    forceChangePwd: 'forceChangePwd'
};

const LoginPage = ()=>{
    const [errorMessage, setErrorMessage] = useState(null);
    const [pageState, setPageState] = useState({
        mode: Modes.normal,
        isLoading: false
    });

    const {state, dispatch} = useContext(CognitoContext);
    const [queryStr] = useSearchParams();
    const navigate = useNavigate();

    const setIsLoading = (isLoading)=> setPageState({...pageState, isLoading});

    const targetUrl = queryStr.get('url') || AppRouteNames.redeemVoucher;
    const username =  queryStr.get('username');

    const onErrorHandler = (err)=>{
        setIsLoading(false);
        try
        {
            console.log('onError', err);
            //window.myErr = err;
            console.log(`err.code=${err.code}`);
            console.log(`err.name=${err.name}`);
            console.log(`err.message=${err.message}`);
           
            const errMessage = CognitoGetErrorMessage(err);
            console.log('CognitoGetErrorMessage', errMessage);
    
            setErrorMessage(errMessage);
        }
        catch{
            console.log(JSON.stringify(err));
            setErrorMessage('Incorrect username or password.');
        }
    };

    const onForceChangePasswordHandler = ({
        username,
        completeNewPasswordHandler
    })=>{
        setPageState({
            ...pageState,
            mode: Modes.forceChangePwd,
            completeNewPasswordHandler, // params: {newPassword, phoneNumber, onPasswordChanged}
            username: username,
            isLoading: false
        });
    };

        
    const navigateToLogin = ()=>{
        dispatch({
            type: UserProviderActions.setSession, 
            session: null
        });
        navigate(AppRouteNames.signin)
    };

    const signInHandler = (model)=>{
        setErrorMessage(null);
        
        CurrentUserStorage.saveCurrentUser(model.username);

        setIsLoading(true);

        
        CognitoSignin({
            cognitoContext: state, 
            username: model.username,
            password: model.password,
            keepMeLoggedIn: model.keepMeLoggedIn,
            onForceChangePassword: onForceChangePasswordHandler,
            onSuccess: (data)=>{
                // fetch user info here
                //CoreServiceSelector.GetUserInfoService(state.session);
                setIsLoading(false);
                navigate(targetUrl);
            },
            onError: onErrorHandler,
            onResetPassword: ()=>{
                setPageState({
                    ...pageState,
                    mode: Modes.resetPwd,
                    isLoading: false
                });
            },
            onUserNotConfirmed:()=>{
                setPageState({
                    ...pageState,
                    mode: Modes.userNotConfirmed,
                    username: model.username,
                    password: model.password,
                    isLoading: false
                });
            },
            onUserRemoved: navigateToLogin
        });
    };

    const refreshUserInfo = async (session) =>{
        
        const service = CoreServiceSelector.GetUserInfoService(session);
        service.setUnauthorisedHandler(navigateToLogin);

        if(!service)
            return;
        setIsLoading(true);
        const data =  await service.getUserInfo(true).catch(err=>{
            console.error(err);
        });
        setIsLoading(false);
    };

    useEffect(()=> {
        if(state.session)
            refreshUserInfo(state.session);
    },[state]);

    const onResetPasswordSuccess = ({username, password})=>{
        signInHandler({username, password, keepMeLoggedIn: true });
    };

    const onForgotPasswordHandler = ()=>{
        setPageState({
            ...pageState,
            mode: Modes.forgotPwd,
            isLoading: false
        });
    };

    const onProcceedToResetPassword = ({username})=>{
        CurrentUserStorage.saveCurrentUser(username);

        setPageState({
            ...pageState,
            mode: Modes.resetPwd
        });
    };

    const onCancelResetPassword =  ()=>{
        setErrorMessage(null);
        setPageState({
            ...pageState,
            mode: Modes.normal
        });
    };

    const onVerificationCompleted = ()=>{
        signInHandler({username: pageState.username, password: pageState.password, keepMeLoggedIn: true });
        //window.location.reload();
    };

    return (
        <AuthContainer>
            <LoadingOverlay isLoading={pageState.isLoading} title="Please wait ...">
                <ToggleView show={pageState.mode === Modes.normal}>
                    <LoginForm
                        keepMeLoggedIn 
                        username={username}
                        signinHandler={signInHandler}
                        onForgotPassword={onForgotPasswordHandler}
                        errorMessage={errorMessage}
                    />
                </ToggleView>
                
                <ToggleView show={pageState.mode === Modes.resetPwd}>
                    <ResetPasswordForm 
                        onSuccess={onResetPasswordSuccess} 
                        onError={onErrorHandler} 
                        errorMessage={errorMessage}
                        onCancel={onCancelResetPassword}
                    />
                </ToggleView>

                <ToggleView show={pageState.mode === Modes.forgotPwd}>
                    <ForgotPasswordForm 
                        onProcceed={onProcceedToResetPassword}
                        errorMessage={errorMessage}
                        onCancel={onCancelResetPassword}
                    />
                </ToggleView>

                <ToggleView show={pageState.mode === Modes.forceChangePwd}>
                   <ForceResetPasswordForm 
                        username={pageState.username}
                        completeNewPasswordHandler={pageState.completeNewPasswordHandler}
                        onSuccess={onResetPasswordSuccess} 
                        onError={onErrorHandler} 
                        errorMessage={errorMessage}
                        onCancel={onCancelResetPassword}
                   />
                </ToggleView>


                
                <ToggleView show={pageState.mode === Modes.userNotConfirmed}>
                    <ToggleView show={errorMessage}>
                        <Alert type={Alerts.danger}>{errorMessage}</Alert>
                    </ToggleView>

                    <Alert type={Alerts.warning}>
                        <p> Your account still needs verification. Please enter the code to complete your account setup. We've sent a verification code to your email address. Please enter the code to complete your account setup.
                        </p>
                    </Alert>
                    <VerifyCodeForm username={pageState.username} onSuccess={onVerificationCompleted} onError={onErrorHandler}/>
                </ToggleView>

            </LoadingOverlay>
        </AuthContainer>
    );
};

export {LoginPage};
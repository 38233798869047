import { Route, Routes } from "react-router-dom";
import { LoginPage } from "../pages/auth/login";
import { SignUpPage } from "../pages/auth/signup";
import { DashboardPage } from "../pages/dashboard";
import { PrintRedemptionPage } from "../pages/gift-cards/print-redemption";
import { RedeemGiftCardPage } from "../pages/gift-cards/redeem";
import { RedemptionHistoryPage } from "../pages/gift-cards/redemption-history";
import { CompanyProfilePage } from "../pages/vendor-profile/company";
import { UserProfilePage } from "../pages/vendor-profile/my-profile";
import { UsersListPage } from "../pages/vendor-profile/users-list";
import { EnvVariablesPage } from "../pages/dashboard/env-variables";
import { ExpressRedemptionPage } from "../pages/gift-cards/express-redemption";
import { useState } from "react";
import { RedeemVoucherPage } from "../pages/gift-cards/redeem-voucher";
import { AddUserPage } from "../pages/vendor-profile/add-user";
import { EditUserPage } from "../pages/vendor-profile/edit-user";

//(process.env.NODE_ENV === "production")

const AppRouteNames = {
  dashboard: '/dashboard',
  signin: '/signin',
  signup: '/signup',
  redeemVoucher: '/redeem-voucher',
  redemptionHistory: '/redemption-history',
  printRedemption: '/print-redemption/:type/:id',
  companyProfile: '/company-profile',
  envVariables: '/env-variables',
  userProfile: '/my-profile',
  usersList: '/users-list',
  addUser: '/add-user',
  editUser: '/edit-user/:id',
  expressRedemption: '/redeem-without-login',
  advancedRedeemCard:'/advancedRedeemCard'
};



const MainRoutedPage = (props)=> {
  const [redemptionCode,setRedemptionCode]=useState(null)
    function setRedemptionId(PassCode){
      setRedemptionCode(PassCode);
      props.setRedemptionCode(PassCode)
    }
    return (
        
        <Routes>
          <Route exact path="/" element={<RedeemVoucherPage />} />
          <Route path={AppRouteNames.signin} element={<LoginPage />} />
          <Route path={AppRouteNames.signup} element={<SignUpPage />} />
          <Route path={AppRouteNames.dashboard} element={<DashboardPage />} />
          <Route path={AppRouteNames.redeemVoucher} element={<RedeemVoucherPage />} />
          {/* <Route path={AppRouteNames.advancedRedeemCard} element={<AdvancedRedeemCard />} /> */}
          <Route path={AppRouteNames.redemptionHistory} element={<RedemptionHistoryPage />} />
          <Route path={AppRouteNames.printRedemption} element={<PrintRedemptionPage />} />
          <Route path={AppRouteNames.expressRedemption} element={<ExpressRedemptionPage setRedemptionId={setRedemptionId}/>} />
          <Route path={AppRouteNames.companyProfile} element={<CompanyProfilePage />} />
          <Route path={AppRouteNames.userProfile} element={<UserProfilePage />} />
          <Route path={AppRouteNames.usersList} element={<UsersListPage />} />
          <Route path={AppRouteNames.addUser} element={<AddUserPage />} />
          <Route path={AppRouteNames.editUser} element={<EditUserPage />} />
          <Route path={AppRouteNames.envVariables} element={<EnvVariablesPage />} />
        </Routes>
      
    );
};

export {MainRoutedPage, AppRouteNames};


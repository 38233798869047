import styled from "styled-components";
import { FormatDecimal } from "../../library/core/formats";
import { Alert, Alerts } from "../bootstrap/alert";
import { InfoDiv } from "../common/card-number-box";


export const RedemptionSuccessMessage = ({
    showMessage, 
    totalBillAmount, 
    redeemedAmount,
    totalRedeemed,
    digits
    })=>{
    
    if(!showMessage || totalBillAmount <= 0)
        return null;
    const remaining = totalBillAmount > totalRedeemed ? totalBillAmount - totalRedeemed: 0;

    digits ||= 2;

    if(remaining <= 0)
    {
        return (
            <Alert type={Alerts.success} hide={false}>
                <InfoDiv>
                    <strong>
                        Transaction complete. Customer's bill for ${FormatDecimal(totalBillAmount, digits)} has been fully redeemed.
                    </strong> Click below to start a new transaction for a different bill.
                </InfoDiv>
            </Alert>
        );
    }

    return (
        <Alert type={Alerts.success} hide={false}>
            <InfoDiv>
                <strong>
                    ${FormatDecimal(totalRedeemed, digits)} successfully redeemed, customer's remaining bill is ${FormatDecimal(remaining, digits)}.
                </strong> Redeem remainder using another gift card/certificate, 
                collect remainder from customer by other means, or start a new transaction for a different bill.
            </InfoDiv>
        </Alert>
    );

};

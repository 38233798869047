import {useContext, useEffect, useState } from 'react'
import { FormInput } from '../form/input';
import { ValidateFormView } from '../form/core';
import { Link } from 'react-router-dom';
import { CompanySelectInput } from '../form/company-dropdown';
import { CognitoContext, UserProviderActions } from '../../contexts/cognito/cognito-provider';
import { CoreServiceSelector } from '../../services/core/service-selector';
import { AccountStatus } from './account-status';
import { CheckBox } from './check-box';
import { FormRenderer } from '../form/form-render';
import { TagEditor } from '../common/tag-editor';
import { PASSWORD_HINT, isValidPassword } from '../../library/core/utils';
import { InlineConfirmDialog } from '../common/inline-confirm-dialog';
import { ToggleView } from '../views/toggle-view';

//https://github.com/react-tags/react-tags


const getFormView = ({isNew})=> {
  const fields =[
    {
      name: "Email",
      label: "Email",
      type: 'email',
      required: true,
      readOnly: !isNew,
      disabled: !isNew
    },
    {
      name: "FirstName",
      label: "First name",
      type: 'text',
      required: true
    },
    {
      name: "LastName",
      label: "Last name",
      type: 'text',
      required: true
    }
  ];
  if(isNew){
    
    fields.push({
      name: 'TempPassword',
      label: 'Temporary Password',
      type: 'text',
      hint: `This is a temp. password, the user is required to change it after first login (${PASSWORD_HINT})`,
      required: true,
      validate: (value)=>{
        if(!isValidPassword(value))
          return PASSWORD_HINT;
        return null;
      }
      
    });
  }

  return {
    fields
  };
};

const userRoles = [
  {name:'redeem', label: 'Redeem cards and certificates'}, 
  {name:'history', label:'View redemption history'}, 
  {name:'useradmin', label:'Manage all users and their access'}];


const PortalUserForm = ({
  isNew, 
  data, 
  onLoading, 
  onSave, 
  onEnableAccount,
  onDelete
})=> {
   if(isNew){
    data.UserRoles ||= 'redeem,history';
    }
   const [isMyAccount, setIsMyAccount] = useState(false);
   const [model, setModel] = useState({});
   const [dialogState, setDialogState] = useState({});
   const [companyOptions, setCompanyOptions] = useState({ });
   const [modelErrors, setModelErrors] = useState({}); 
   const [refreshKey, setRefreshKey] = useState(1);
   const {state, dispatch} = useContext(CognitoContext);
   const formView = getFormView({isNew});

    const getUserInfo = async (forceRefresh, refreshPage)=>{
      if(!state.session)
          return;

      const userInfoService = CoreServiceSelector.GetUserInfoService(state.session);
      
      let userInfo = state.userInfo || userInfoService.getSavedUserInfo();

      if(forceRefresh || !userInfo)
      {
          
          userInfo =  await userInfoService.getUserInfo(forceRefresh).catch(console.error);

          dispatch({type: UserProviderActions.setUserInfo, userInfo});
          if(!userInfo)
              return;          
      }

      if(userInfo)
      {
          const gOptions = {
              options: userInfo.GroupCompanies.map(m =>{
                  return {
                      id: m.Id,
                      name: m.Name
                  }
              }),
              defaultCompanyId: userInfo.CompanyId,
              readOnly: (!userInfo.HasFullGroupAccess || userInfo.GroupCompanies.length < 2)
          };
          setCompanyOptions(gOptions);

          
          if(refreshPage)
          {
            setRefreshKey(1 + refreshKey);
          }
      }
      
      onLoading && onLoading(false);
    };

   const onSubmitHandler = (event)=>{
      event.preventDefault();
      const errs = ValidateFormView({fields: formView.fields, model});
      setModelErrors(errs);
  
      if(!errs || Object.keys(errs).length === 0)
      {
        onSave && onSave(model);
      }
   };

   const onFieldChangeHandler = (event) => {
      setModel({
          ...model,
          [event.target.name]: event.target.value  
      });
   };

   const onSetTargetCompany = (companyId)=>{
      setModel({
          ...model,
          PartnerCompanyId: companyId
      });
   };

   useEffect(()=>{
      
      getUserInfo(false, true);
   },[state]);

   useEffect(()=>{
      if(isNew || !state.session || !state.userInfo || !model || !model.Email)
        return;

      setIsMyAccount(state.userInfo.Email === model.Email);
    }, [model, state]);

   useEffect(()=>{
      setModel({
        ...data,
        PartnerCompanyId: data.PartnerCompanyId || companyOptions.defaultCompanyId
      });
   }, [data, companyOptions]);
   
  
  const onUserRolesChanged = (value, oldValue)=>{
    if(value === '')
      return;

      setModel({
        ...model,
        UserRoles: value  
      });
  };

  const onEnableAccountHandler = ()=>{
    onEnableAccount && onEnableAccount(model);
  };

  const onDismissDialog = ()=>{
    setDialogState({});
  }

  const onConfirmDeleteUser = ()=>{
    onDismissDialog();
    onDelete && onDelete(model);
  };

  const onDeleteUserRequested = ()=>{
    setDialogState({
        show: true,
        action: "delete-user",
        alertType: "danger",
        yesAction: onConfirmDeleteUser,
        noAction: onDismissDialog,
        body: (<div>You are about to delete this user! are you sure you to continue?</div>)
    });
  };

   return (
      <form>
        {isMyAccount?(
          <div className="alert alert-warning">
              <div>
                <h5>Caution</h5>
                <div>
                  <span className="bi bi-exclamation-circle-fill" style={{marginRight:"10px", fontSize:"18px"}}/> 
                  You are editing your own account! Be careful when changing your access level.
          
                </div>
              </div>
              
            </div>
        ):null}
        <AccountStatus  dataModel={model} label="Account Status" show={!isNew} onEnableAccount={onEnableAccountHandler}/>
       
        <CompanySelectInput
            label="Venue"
            companyIdFieldName="PartnerCompanyId"
            key={refreshKey}
            dataModel={model} 
            {...companyOptions} 
            onChange={onSetTargetCompany}
        />

        <FormRenderer formView={formView} dataModel={model} errorsModel={modelErrors} onChange={onFieldChangeHandler}/>
       
        <TagEditor value={model.UserRoles} options={userRoles} onChange={onUserRolesChanged} />

        <CheckBox 
          show={companyOptions.options && companyOptions.options.length > 1} 
          dataModel={model}
          name="HasFullGroupAccess"
          label="Assign this user the same roles for all venues in your group"
          onChange={onFieldChangeHandler}
          
        />

        

        <div className="row">
          <div className="col-9">
            <button 
                type="button" 
                className="btn btn-primary"
                onClick={onSubmitHandler}
                style={{
                  minWidth: "100px"
                }}
              >
                {isNew?"Create User":"Save"}
              
            </button>

            <Link 
              className="btn btn-secondary" 
              target="_self"
              to={{ pathname:`/users-list`}}
              style={{
                marginLeft: "10px",
                minWidth: "100px"
              }}
            >
              {isNew?"Cancel":"Back to List"}
            </Link>
          </div>
          <div className="col-3">
            <ToggleView show={!isNew && !isMyAccount && dialogState.action !== 'delete-user'}>
              <button 
                  type="button" 
                  className="btn btn-danger"
                  onClick={onDeleteUserRequested}
                  style={{
                    minWidth: "100px"
                  }}
                >
                Delete User
              </button>
            </ToggleView>
           
           
          </div>
        </div>

        <InlineConfirmDialog {...dialogState} />
      </form>
    );
};


export {PortalUserForm};
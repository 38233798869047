
import { CoreService } from "./core-service";


export class RedemptionService extends CoreService
{
    constructor(session){
        super(session);
    }
    
    getBaseUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api`;

    getHistoryUrl = (companyId) => `${this.getBaseUrl()}/universal-redemption/get-merged-redemption-history${companyId?(`?companyId=${companyId}`):""}`;
    getRedeemUrl = () => `${this.getBaseUrl()}/universal-redemption/redeem-voucher`;

    getVoucherInfoUrl = ()=> `${this.getBaseUrl()}/voucher-info/get-voucher-info`;

    getRedemptionRecordUrl = (redemptionId, type) => `${this.getBaseUrl()}/universal-redemption/history/record/${type}/${redemptionId}`;

    getRedemptionsHistory = async (companyId)=>{
        if(!this.session)   //TODO: also check if session is valid
            return null;

        const url = this.getHistoryUrl(companyId);
        
        const result = await this.callWebApi('GET', url, null);
        return result.data;
    }

    getRedemptionRecord = async (redemptionId, type, companyId)=>{
        
        if(!this.session)   //TODO: also check if session is valid
            return null;
        let url = this.getRedemptionRecordUrl(redemptionId, type) + (companyId?`?companyId=${companyId}`:"");

        const result = await this.callWebApi('GET', url, null);
        return result.data;
    }

    redeemCard = async (request)=>{
        const { 
            CompanyId,
            CardNumber,
            PinCode,
            Amount,
            Source,
            DeviceType,
            OsName,
            DeviceSerial,
            TotalBillAmount,
            TransactionGroupId,
            IsNewCustomer,
            AppVersion
        } = request;


        const result = await this.callWebApi(
            'POST', 
            this.getRedeemUrl(), 
            request
        );

        
        return result.data;
    }

    getVoucherInfo = async (barcode, companyId)=>{
        const result = await this.callWebApi(
            'POST', 
            this.getVoucherInfoUrl(), 
            {
                Barcode: barcode,
                CompanyId: companyId
            }
        );

        return result.data;
    }
} 

import {CognitoUserAttribute} from 'amazon-cognito-identity-js';

const CognitoSignup = ({cognitoContext, username, password, attrs, onSuccess, onError})=>{
    
    const keys = Object.keys(attrs);
    const attributeList = keys.map(attrName => new CognitoUserAttribute({
        Name: attrName,
        Value: attrs[attrName]
    }))

    cognitoContext.userPool.signUp(
        username, 
        password, 
        attributeList, 
        null, 
        function(
            err,
            result
        ) {
            if (err) {
                onError && onError(err.message || JSON.stringify(err));
                return;
            }
            
            onSuccess && onSuccess({
                user : result.user
            });
        }
    );
};


export {CognitoSignup};

import { RedemptionService } from "../core/redemption-service";


export class DevRedemptionService extends RedemptionService
{
    constructor(session){
        super(session);
    }
    getBaseUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api`;

    
} 

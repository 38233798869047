import styled from "styled-components";
import { PortalSideMenu } from "../side-menu";
import { UnauthorisedPerson } from "../auth/unauthorised-person";

const TitleDivCss = ({printTitle})=>{
    if(!printTitle)
        return '@media print {display: none;}';
    return null;
};

const TitleDiv = styled.div`
    ${(props)=>TitleDivCss(props)}
`;

const OffCanvas = styled.div`
    display: none;
    @media (min-width: 426px){ display: block;}
`;


const MainPageDiv = styled.div`
   
`;


const Container = styled.div`
    margin-bottom: 150px;
`;


export const PortalMainLayout = ({title, children, isUnauthorised})=>{
    return (
        <Container className="container-fluid">

             <div className="row">
                <OffCanvas className="col-sm-4 col-lg-4 col-xl-3 col-xxl-2">
                    <PortalSideMenu asSideMenu={true}/>                   
                </OffCanvas>

                <MainPageDiv className="col-12 col-sm-8 col-lg-8 col-xl-9 col-xxl-10">
                    <TitleDiv>
                        <h1>{title}</h1>
                    </TitleDiv>
                    {
                        isUnauthorised?(
                            <UnauthorisedPerson show={true}/>
                        ):(
                        <div>
                            {children}
                        </div>)
                    }
                    
                </MainPageDiv>
            </div> 
        </Container>
    );
};

import { CoreSignupInfoService } from "../core/signup-info-service";

export class ProdSignupInfoService extends CoreSignupInfoService
{
    constructor(session){
        super(session);
    }

    getBaseUrl = ()=> `https://prod.best-experiences.com/invitation-signup-info/api/new-vendors-portal/invitation-info`;


}


import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { AuthorisedPage } from "../auth/authorised-page";
import '../../css/print-redemption.css';
import { format } from "date-fns";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CoreServiceSelector, ServiceOf } from "../../services/core/service-selector";
import { VoucherTypes } from "../../library/core/enums";

export const PrintRedemptionPage = (props)=>{
    const urlParams = useParams();
    const [optionals, tow] = useSearchParams();
    const [unauthorised, setUnauthorised] = useState({show: false});
    const {state, dispatch} = useContext(CognitoContext);
    const [pageState, setPageState] = useState({
        isLoading: true,
        hasErrors: false,
        now: format(new Date(), 'dd/MM/yyyy HH:mm')
    });
    
    
    const getRecord = async ()=>{
        const companyId = optionals.get("companyId");

        const redemptionId = urlParams.id;
        const type = urlParams.type;

        if(!redemptionId || !type)
            return;

        const service = CoreServiceSelector.GetRedemptionService(state.session);
        if(!service)
            return;

        setPageState({
            ...pageState,
            redemptionId,
            isLoading: true
        });

        const result =  await service
                                    .getRedemptionRecord(redemptionId, type, companyId)
                                    .catch((err)=>{
                                        if(err.response.status === 401){
                                            setUnauthorised({show: true});
                                        }

                                        setPageState({
                                            isLoading: false,
                                            hasErrors: true
                                        });
                                    });
        
        
        if(result)
        {
            setPageState({
                ...pageState,
                isLoading: false,
                record: result
            });
        }
        
    };

    useEffect(()=>{
        getRecord();
    },[state]);
    
    const Loading = ({show})=>{
        if(!show)
            return null;
        return <div>Loading ...</div>;
    };

    
    return (
        <AuthorisedPage>
            <PortalMainLayout title="Print redemption" isUnauthorised={unauthorised.show}>
                <Loading show={pageState.isLoading && !pageState.hasErrors}/>
                {
                    (!pageState.record)?(null):(
                                
                            <div className="receipt-body">
                                <div className="receipt-container">               
                                    <div className="receipt">
                                        <div className="header">
                                            <div className="company-name">{pageState.record.CompanyName}</div>
                                            <div className="address">{pageState.record.AddressLine1}, <br></br>
                                            {pageState.record.AddressLine2}{pageState.record.AddressLine2 && pageState.record.AddressLine2.length > 0?<>,<br></br></>:""}
                                            {pageState.record.Suburb}, {pageState.record.State} {pageState.record.Postcode}</div>
                                        </div>

                                        <div className="body-of-receipt">
                                            <div className="narrow">
                                                <div className="row pull-left">
                                                    <span className="label pull-right">Receipt Date:</span>
                                                    <span className="field"> {pageState.record.FormattedDate}</span>
                                                </div>
                                                <div className="row pull-left">
                                                    <span className="label">Processed by:</span>
                                                    <span className="field">  {pageState.record.UserName}</span>
                                                </div>
                                            </div>

                                            <div className="row"><h4> {pageState.record.VoucherTypeName} Redemption</h4></div>


                                            <div className="row">RECEIPT #{pageState.record.ReceiptNumber}</div>
                                            {pageState.record.VoucherType === VoucherTypes.GiftCard?(<div className="row">GIVEX TRX ({pageState.record.TransNumber})</div>):null}
                                            
                                            <div className="box">
                                                <div className="row pull-left">
                                                    <span className="label">Card Number:</span>
                                                    <span className="field">{pageState.record.CardNumber}</span>
                                                </div>
                                                <div className="row pull-left">
                                                    <span className="label">Claimed Amount:</span>
                                                    <span className="field"> {pageState.record.FormattedAmount}</span>
                                                </div>
                                                <div className="row pull-left">
                                                    <span className="label">Remaining Balance:</span>
                                                    <span className="field"> {pageState.record.FormattedBalance}</span>
                                                </div>
                                                <div className="row pull-left">
                                                    <span className="label">Expiry Date:</span>
                                                    <span className="field"> {pageState.record.FormattedExpiryDate}</span>
                                                </div>
                                            </div>

                                            <div className="row pull-left pull-down">
                                                <span className="label">Print Date:</span> {pageState.now}</div>
                                            <div className="row pull-down">Thank You!!</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                
                    )                    
                }
                {
                    // (pageState.hasErrors)?<div><div className="alert alert-danger">Oops something wrong happened, Failed to load record</div></div>:null
                }
                
            </PortalMainLayout>
        </AuthorisedPage>
    );
};

import { useLocation } from "react-router-dom";
import styled from "styled-components";

const IconSpan = styled.span`
    display: inline-block;
    padding-right: 15px;
`;


const MenuListItem = styled.li`
   list-style: none;   
`;


const LinkItem = styled.a`
    display: block;
    padding: 5px;
    text-decoration: none;
    color:var(----theme-background-color)
`;

const AccordionBody = styled.div`
    padding: 5px 0;
`;

const TopMenuLi = styled.li`
    display: list-item;
    margin-left: 3px;
    @media (min-width: 426px){ display: none;}
`;


const accordionMenuID = 'main-accordion-menu';

const Icon = ({ name }) => {
  if (!name) return null;
  const iconName = `bi ${name}`;
  return (
    <IconSpan>
      <i className={iconName}></i>
    </IconSpan>
  );
};

export const SubMenuItems = ({items})=>{
    items = items || [];
    
    return (
            <ul style={{paddingLeft:"5px"}}>
                {
                    items.map((item, index) => (
                        <MenuListItem key={index}>
                            <LinkItem href={item.url}><Icon name={item.icon || 'bi-caret-right'} /> {item.title}</LinkItem>
                        </MenuListItem>
                    ))
                }
            </ul>
    );
}

export const MenuItems = ({items, asSideMenu, asTopMenu})=>{
    const location = useLocation();
    let pathname = location.pathname;
    if(pathname === '/')
        pathname = '/redeem-voucher';

    items = items || [];
    const accordionItems = items.map((item, index)=> {
        const headingId = `heading-${index}`;
        const collapseId = `collapse-${index}`;
        
        const subItems = item.items || [];
        const selectedItem = subItems.find(m=> m.url && m.url.includes(pathname));
        const isOpen = pathname.length > 1 && selectedItem != null && selectedItem !== undefined;
        
        if(asSideMenu)
        {
            return (
                <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={headingId}>
                        <button 
                            className={`accordion-button`}
                            type="button" 
                            //data-bs-toggle="collapse" 
                            data-bs-target={`#${collapseId}`} 
                            aria-expanded="true" 
                            aria-controls={collapseId}
                        >
                            <Icon name={item.icon || 'bi-caret-right'} /> {item.title}
                        </button>
                    </h2>
    
                    <div 
                        id={collapseId} 
                        className={`accordion-collapse show`}
                        aria-labelledby={headingId} 
                        //data-bs-parent={`#${accordionMenuID}`}
                    >
                        <AccordionBody className="accordion-body">
                            <SubMenuItems items={item.items}/>
                        </AccordionBody>
                    </div>
                </div>
            );
        }
        if(asTopMenu)
        {
            const ITEM=({index, item})=>{
                return (
                <>
                    <TopMenuLi key={`title-${index}`}> <Icon name={item.icon || 'bi-caret-right'} /> {item.title} </TopMenuLi>
                    {
                        (item.items || []).map((sItem,index)=>(
                            <TopMenuLi key={`submenu-${index}-${sItem.title}`}>
                                <a className="dropdown-item" href={sItem.url}>
                                    <Icon name={sItem.icon || 'bi-caret-right'} /> {sItem.title}
                                </a>
                            </TopMenuLi>))
                    }
                    <TopMenuLi key={`divider-${index}`}><hr className="dropdown-divider"/></TopMenuLi>
                </>
                );
            };
            return (<ITEM key={index} index={index} item={item}/>);
        }
        return null;
        
    });

    if(asSideMenu)
        return (
            <div className="accordion" id={accordionMenuID}>
                {
                    accordionItems
                }
            </div>
        );

    if(asTopMenu)
      return accordionItems;

    return null;
};

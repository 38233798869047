import { ToggleView } from "../views/toggle-view";
import { InvalidInputMessage } from "./invalid-input";

export const FormInput = ({
    dataModel,
    name,
    label,
    hint,
    type,
    defaultValue,
    required,
    readOnly,
    disabled,
    autoComplete,
    onChange,
    errorsModel
})=>{
    const hintId = `${name}Hint`;
    dataModel = dataModel || {};
    const value = dataModel[name] || defaultValue || '';
    const errorMessage = errorsModel && errorsModel[name];
    autoComplete ||= "on";

    return (
        <div className="mb-3">
            <label htmlFor={name} className="form-label">{label || name}{required?" *":""}</label>
            <input
                required={required}
                readOnly={readOnly}
                disabled={disabled}
                autoComplete={autoComplete}
                type={type || "text"} 
                className={"form-control" + (errorMessage?" is-invalid":"")}
                name={name} 
                id={name} 
                aria-describedby={hintId}
                value={value}
                onChange={onChange}
            />
            <InvalidInputMessage errorMessage={errorMessage}/>
            <ToggleView show={hint}>
                <div id={hintId} className="form-text">{hint}</div>
            </ToggleView>
            
        </div>
    );
};


import { useContext, useEffect, useState } from "react";
import { DataTable } from "../../components/bootstrap/data-table";
import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CoreServiceSelector, ServiceOf } from "../../services/core/service-selector";
import { MockRedemptionService } from "../../services/mock/redemption-service";
import { DevRedemptionService } from "../../services/dev/redemption-service";
import { ProdRedemptionService } from "../../services/prod/redemption-service";
import { AuthorisedPage } from "../auth/authorised-page";
import { LoadingOverlay } from "../../components/layout/loading-overlay";
import { Link } from "react-router-dom";
import { AppRouteNames } from "../../routes/app-routes";
import { ToggleView } from "../../components/views/toggle-view";
import { CompanySelectInput } from "../../components/form/company-dropdown";
import {UnauthorisedPerson} from "../../components/auth/unauthorised-person";
const dataModel = {
    fields:[
        {
            name: 'VoucherTypeName',
            label: 'Type'
        },
      
        {
            name: 'ReceiptNumber',
            label: 'Receipt No.'
        }, 
        {
            name: 'FormattedDate',
            label: 'Date'
        }, 
        {
            name: 'FormattedAmount',
            label: 'Amount'
        }, 
        {
            name: 'CardNumber',
            label: 'Card Number'
        }, 
        {
            name: 'RedemptionStatusName',
            label: 'Status'
        }, 
        {
            name: 'UserName',
            label: 'User Name'
        },
        {
            name: 'print',
            label: '',
            isCustom: true,
            render: (row)=>{
                return (
                    <Link className="btn btn-link" target="_blank"
                        to={
                        {
                            pathname:(`/print-redemption/${row.VoucherType}/${row.Id}`),
                            search:(row.CompanyId?`?companyId=${row.CompanyId}`:"")
                        }
                    }>
                    Print
                  </Link>
                );
            }
        }
    ]
};
/*
<a className="btn btn-link" href={`/print-redemption?id=${row.id}`} target="_self">Print</a>
*/
export const RedemptionHistoryPage = ()=>{
    const [data, setData] = useState([]);
    const [pageState, setPageState] = useState({
        isLoading: true
    });
    const [unauthorised, setUnauthorised] = useState({show: false});

    const [selectedCompany, setSelectedCompany] = useState({});

    const {state, dispatch} = useContext(CognitoContext);
    const [companyOptions, setCompanyOptions] = useState({ });

    const getRedemptionHistory = async (companyId)=>{
        const service = CoreServiceSelector.GetRedemptionService(state.session);
        if(!service)
            return;
            
        setPageState({
            ...pageState,
            isLoading: true
        });
        const result =  await service
            .getRedemptionsHistory(companyId)
            .catch(err =>{
                if(err.response.status === 401){
                    setUnauthorised({show: true});
                }
            });

        setData(result || []);
        setPageState({
            ...pageState,
            isLoading: false
        });
    };

   

    useEffect(()=>{
        if(state.userInfo)
        {
            const userInfo = state.userInfo;
            const gOptions = {
                options: userInfo.GroupCompanies.map(m =>{
                    return {
                        id: m.Id,
                        name: m.Name
                    }
                }),
                defaultCompanyId: userInfo.CompanyId,
                readOnly: (!userInfo.HasFullGroupAccess || userInfo.GroupCompanies.length < 2),
                showButton: true,
                buttonText: 'View History',
                onButtonClick: async (companyId)=>{
                    await getRedemptionHistory(companyId);
                }
            };
            setSelectedCompany({companyId: userInfo.CompanyId});
            setCompanyOptions(gOptions);  
        }
       getRedemptionHistory();
    },[state]);


    const onSetTargetCompany = (companyId)=>{
        setSelectedCompany({companyId});
    };

    
    return (
        <AuthorisedPage>
            <PortalMainLayout title="Redemption History" isUnauthorised={unauthorised.show}>
                <div>
                    <LoadingOverlay isLoading={pageState.isLoading} title="Please wait" message="loading history">
                        <ToggleView show={companyOptions}>
                            <CompanySelectInput dataModel={selectedCompany} {...companyOptions} onChange={onSetTargetCompany}/>
                        </ToggleView>

                        <DataTable 
                                fields={dataModel.fields}
                                rows={data}
                        />
                   </LoadingOverlay>
                </div>
            </PortalMainLayout>
        </AuthorisedPage>
    );
};

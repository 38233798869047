import styled from "styled-components";

const Container= styled.div`

`;

//text-transform: capitalize;

const TagOptions= styled.div`
    padding: 20px;
    label, input {
        cursor: pointer;
    }
`;

const TagOption = ({name, label, selected, onChange})=>{
    const onChangeHandler = (event)=>{
        onChange && onChange({
            name,
            selected: event.target.checked
        });
    };

    return (
        <div className="mb-3">
            <input
                checked={selected?"checked":null}
                type="checkbox"
                name={name}
                id={name}
                onChange={onChangeHandler}
            />
            <label htmlFor={name} style={{
                    display: "inline-block",
                    marginLeft: "10px"
                }}>{label || name} </label>
        </div>
    );
};


const isInTagsArray = (tag, tagsArray)=>{
    tag ||= '';
    return ((tagsArray || []).filter(item => item === tag)).length > 0;
};

export const TagEditor = ({options, value, onChange, delim, allowEmpty})=>{
    delim ||= ",";
    let tagsArray = (value || "").split(delim);
    
    const onChangeHandler = ({name, selected})=>{
        const index = tagsArray.findIndex(tag => tag === name);

        if(selected && index < 0)
        {
            tagsArray.push(name);
        }
        else if(!selected && index >= 0)
        {
            tagsArray.splice(index, 1);
        }

        if(!allowEmpty && tagsArray.length === 0)
        {
            tagsArray = value.split(delim);
            return;
        }   
        onChange && onChange(tagsArray.join(delim), value);
    };

    return (
        <Container>
            <div>
                <label>User Roles</label> (choose one at least)
            </div>
            <TagOptions>
            {
                options.map(option => <TagOption 
                                        key={option.name}
                                        name={option.name}
                                        label={option.label}
                                        onChange={onChangeHandler}
                                        selected={isInTagsArray(option.name, tagsArray)}
                                        />)
            }
            </TagOptions>
        </Container>
    );
};

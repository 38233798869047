export const CheckBox = ({
    show,
    dataModel, 
    name, label, 
    onChange, 
    showWarning, 
    warningText
})=>{
    if(!show)
        return null;

    dataModel = dataModel || {};
    const value = dataModel[name] || false;

    const onChangeHandler = (event)=>{
        // [event.target.name]: event.target.value

        onChange && onChange({
            target:{
                name,
                value: event.target.checked
            }
        });
    };
    return (
        <>
        <div className="mb-3">
            <label 
                
                htmlFor={name}
                >{label || name}</label>
            <input
                checked={value?"checked":null}
                type="checkbox"
                name={name}
                id={name}
                value={value}
                onChange={onChangeHandler}
                style={{
                    display: "inline-block",
                    marginLeft: "10px"
                }}
            />
        </div>
        <div>
             {
                showWarning?(
                    <span 
                        style={{
                            display: "inline-block",
                            marginBottom: "15px"
                        }}
                        className="alert alert-warning"
                    >
                        <i className="bi bi-exclamation-triangle-fill"></i>
                        <span style={{marginLeft: "10px"}}>{warningText}</span>
                    </span>):null
            }

        </div>
        </>
    )
};
import {useEffect, useState } from 'react'
import { FormInput } from '../form/input';
import { ValidateFormView } from '../form/core';
import { ToggleView } from '../views/toggle-view';
import { isValidPassword, PASSWORD_HINT } from '../../library/core/utils';



const formView = {
  fields:[
      {
        name: "fullName",
        label: "Full name",
        type: 'text',
        required: true
      },
      {
          name: "email",
          label: "Email address",
          type: 'email',
          required: true
      },
      {
        name: "phone",
        label: "Phone number",
        type: 'tel',
        defaultValue: '+61',
        hint:'All phone numbers should be in international format e.g. +61234567890',
        required: true
      },
      {
          name: 'password',
          label: 'Password',
          type: 'password',
          //hint: PASSWORD_HINT,
          required: true,
          validate: (value)=>{
            if(!isValidPassword(value))
              return PASSWORD_HINT;
            return null;
          }
      },
      {
        name: 'confirmPassword',
        label: 'Confirm password',
        type: 'password',
        required: true,
        validate: (value, dataModel)=> {
            if(value !== dataModel.password)
                return `this field does not match password!`;
            return null;
        }
    }
  ]
};


const SignUpForm = ({signupHandler, data})=> {
   const [model, setModel] = useState({});
   const [modelErrors, setModelErrors] = useState({}); 

   const onSubmitHandler = (event)=>{
      event.preventDefault();
      const errs = ValidateFormView({fields: formView.fields, model});
      setModelErrors(errs);
  
      if(!errs || Object.keys(errs).length === 0)
      {
          signupHandler && signupHandler(model);
      }
   };

   const onFieldChangeHandler = (event) => {
      setModel({
          ...model,
          [event.target.name]: event.target.value  
        });
   };


   useEffect(()=>{
      setModel({
        ...data
      });
   }, [data]);
   return (
      <form>
        
        <ToggleView show={model.companyName}>
          <h3>Set up your account with</h3>
          <h5 style={{
            margin: "0 0 25px 0",
            textAlign: "center"
          }}>{model.companyName}</h5>
        </ToggleView>

        <ToggleView show={!model.companyName}>
          <h3>Sign Up</h3>
        </ToggleView>

        {
            formView.fields.map(field => <FormInput
                                            key={field.name} 
                                            dataModel={model} 
                                            readOnly={model.companyName && model.email && field.name === 'email'}
                                            {...field}
                                            errorsModel={modelErrors}
                                            onChange={onFieldChangeHandler}
                                            />
                                )
        }


        <div className="d-grid">
          <button 
              type="submit" 
              className="btn btn-primary"
              onClick={onSubmitHandler}
            >
            Sign Up
          </button>
        </div>
        <p className="forgot-password text-right">Already registered? <a href="/signin">sign in here</a></p>
      </form>
    );
};


export {SignUpForm};
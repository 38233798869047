import styled from "styled-components";
import { FormatDecimal } from "../../library/core/formats";
import { Alert, Alerts } from "../bootstrap/alert";
import { InfoDiv } from "../common/card-number-box";
import { VoucherTypes } from "../../library/core/enums";



export const TotalBillPreview = ({
    totalBillAmount, 
    totalRedeemed, 
    voucher,
    digits})=>{
    totalBillAmount ||= 0;
    totalRedeemed ||= 0;
    digits ||= 2;

    if(totalBillAmount <= 0)
        return null;

    let voucherInfo = (<></>);
    if(voucher && voucher.VoucherNumber)
    {
        voucherInfo = (
                        (voucher.VoucherKind === VoucherTypes.GiftCard)?
                            <> and the gift card you entered is {voucher.VoucherNumber}</>:
                            <> and the certificate you entered is for <strong>{voucher.ProductName}</strong> ({voucher.VoucherNumber})
                            of <strong>${FormatDecimal(voucher.Balance, digits)}</strong> value
                            </>
                    );
        
    }

    if(totalRedeemed === 0)
        return (
            <Alert type={Alerts.primary} hide={false}>
                <InfoDiv>
                    Customer`s total bill is <span>${FormatDecimal(totalBillAmount, digits)}</span> {voucherInfo}
                </InfoDiv>
            </Alert>
        );
    
    const remaining = totalBillAmount > totalRedeemed ? totalBillAmount - totalRedeemed: 0;

    return (
            <Alert type={Alerts.primary} hide={false}>
                <InfoDiv>
                    <strong> 
                        Customer's remaining unpaid bill is ${FormatDecimal(remaining, digits)}.
                    </strong>
                </InfoDiv>
                <InfoDiv>
                    Redeem remainder using another gift card/certificate, 
                    collect remainder from customer by other means, or start a new transaction for a different bill.
                </InfoDiv>
            </Alert>
        );
};

const VoucherTypeErrors = {
    "2": "Invalid card/certificate number",
    "6": "Card/certificate is already expired",
    "9": "Insufficient funds on card/certificate",
    "40": "card/certificate is inactive",
    "VOUCHER_NOT_FOR_VENDOR": "This gift certificate is only valid for a specific experience at a different venue, try a different card/certificate",
    "CERT_IS_ALREADY_REDEEMED": "Certificate already used, try a different gift card/certificate",
    "EXPIRED_CERTIFICATE": "Certificate is expired, please try a different card/certificate"
};


export const isInvalidVoucher = (data)=> (
                                        data.IsInvalidVoucher || 
                                        !data.CanBeRedeemed || 
                                        data.HasErrors ||
                                        data.VoucherIsInactive || 
                                        data.IsExpired
                                    );

export const getVoucherError = (response)=>{
    if(response.IsInvalidVoucher)
        return VoucherTypeErrors["2"];
    
    if(response.IsExpired && (!response.ErrorCode || response.ErrorCode === "0"))
        response.ErrorCode="6";

    if(!response.CanBeRedeemed && (!response.ErrorCode || response.ErrorCode === "0"))
        response.ErrorCode="2";

    if(response.ErrorCode && response.ErrorCode !== "0")
        return VoucherTypeErrors[response.ErrorCode] || response.ErrorMessage;

    return null;
};
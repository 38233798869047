import {CognitoUser} from 'amazon-cognito-identity-js';


const errorMessagesMap = {
    NotAuthorizedException: 'Incorrect username or password.',
    PasswordResetRequiredException: 'Password reset is required',
    CodeMismatchException: 'Invalid code entered, try again or request a new code.',
    'Invalid verification code provided, please try again.': 'Invalid code entered, try again or request a new code.',
    LimitExceededException: 'Attempt limit exceeded, please try after some time.',
    InvalidPasswordException: 'Invalid password! password should be at least 8 characters with 1 number, 1 symbol, 1 capital and 1 lowercase.'
};

export const CognitoGetErrorMessage = (err) => {
    
    if(!err)
        return null;
    console.log('err.code: '+ err.code);
    console.log('err.__type: '+ err.__type);

    if(typeof err === 'string')
        return err;


    return (err.code && errorMessagesMap[err.code]) || 
    (err.__type && errorMessagesMap[err.__type]) || 
    (err.message) || err.code || err.__type || err;
};

export const CognitoConfirmSignup = ({cognitoContext, username, verifyCode, onSuccess, onError})=> {
    var cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoContext.userPool,
    });

    cognitoUser.confirmRegistration(
        verifyCode, 
        true, 
        function(err, result) {
            if (err) {
                onError && onError(err);//onError(err.message || JSON.stringify(err));
                return;
            }
            onSuccess && onSuccess(result);
        }
    );
};



export const CognitoResetPassword = ({cognitoContext, username, verifyCode, password, onSuccess, onError})=> {
    var cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoContext.userPool,
    });

    cognitoUser.confirmPassword(
        verifyCode, 
        password, 
        {
            onSuccess: onSuccess,
            onFailure: onError
        }
    );
};



export const ResendConfirmCode = ({cognitoContext, username, onSuccess, onError})=> {
    var cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoContext.userPool,
    });

    cognitoUser.resendConfirmationCode(
        function(err, result) {
            if (err) {
                onError && onError(err.message || JSON.stringify(err));
                return;
            }
            onSuccess && onSuccess(result);
        }
    );
};


export const ResendForgotPassword = ({cognitoContext, username, onSuccess, onError})=> {
    var cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoContext.userPool,
    });

    cognitoUser.forgotPassword(
        {
            onSuccess: onSuccess,
            onFailure: onError
        }
    );
};




import { UserInfoService } from "../core/user-info-service";

export class ProdUserInfoService extends UserInfoService
{
    constructor(session){
        super(session);
    }
    
    getUserMenuUrl = ()=> `https://prod.best-experiences.com/withlogin-universal-redemptions/api/user-info/user-menu`;
    getUserInfoUrl = ()=> `https://prod.best-experiences.com/withlogin-universal-redemptions/api/user-info`;

} 

import { CoreService } from "./core-service";

export class CoreSignupInfoService extends CoreService
{
    constructor(session){
        super(session);
    }

    needsToken = ()=> false;

    getBaseUrl = ()=> `https://dev.best-experiences.com/invitation-signup-info/api/new-vendors-portal/invitation-info`;

    getUrl = (id, guid)=> `${this.getBaseUrl()}/${id}/${guid}`;

    getApiKey = ()=> 'x';

    getSignupInfo = async (id, guid)=>{
        const result = await this.callWebApi('GET', this.getUrl(id, guid), null);
        return result.data;
    }

}
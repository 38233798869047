
import { useContext, useEffect, useState } from "react";
import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { CognitoContext, UserProviderActions } from "../../contexts/cognito/cognito-provider";
import { AuthorisedPage } from "../auth/authorised-page";
import { LoadingOverlay } from "../../components/layout/loading-overlay";
import { RedeemVoucher } from "../../components/gift-cards/redeem-voucher";
import { CoreServiceSelector } from "../../services/core/service-selector";
import { useNavigate } from "react-router-dom";
import { AppRouteNames } from "../../routes/app-routes";

export const RedeemVoucherPage = ()=>{
    const {state, dispatch} = useContext(CognitoContext);
    const [unauthorised, setUnauthorised] = useState({show: false});
    const [companyOptions, setCompanyOptions] = useState({ });
    const [pageState, setPageState] = useState({
        isLoading: true,
        key: 1
    });
    const navigate = useNavigate();

    const navigateToLogin = ()=>{
        dispatch({
            type: UserProviderActions.setSession, 
            session: null
        });
        navigate(AppRouteNames.signin)
    };

    const getUserInfo = async (forceRefresh, refreshPage)=>{
        if(!state.session)
            return;

        const userInfoService = CoreServiceSelector.GetUserInfoService(state.session);
        
        if(forceRefresh || !userInfoService.getSavedUserInfo() || !state.userInfo)
        {
            const userInfo =  await userInfoService.getUserInfo(forceRefresh).catch(err=>{
                navigateToLogin();
            });

            dispatch({type: UserProviderActions.setUserInfo, userInfo});
            if(!userInfo)
                return;

            const gOptions = {
                options: userInfo.GroupCompanies.map(m =>{
                    return {
                        id: m.Id,
                        name: m.Name
                    }
                }),
                defaultCompanyId: userInfo.CompanyId,
                readOnly: (!userInfo.HasFullGroupAccess || userInfo.GroupCompanies.length < 2)
            };
            setCompanyOptions(gOptions);


        }

        setPageState({
            ...pageState,
            isLoading: false,
            key: (refreshPage? 1 : 0) + pageState.key
        });
    };

    useEffect(()=>{
        getUserInfo(false, true);
        if(state && state.userInfo)
        {
            setPageState({
                ...pageState,
                isLoading: false
            });

            if(state.userInfo.UserRoles.findIndex(role=> role === 'redeem') < 0)
            {
                setUnauthorised({show: true});
            }
        }
    },[state]);

    const onTrxCompletedHandler = async ()=>{
        
    };

    const onNewTransactionHandler = async ()=>{
        await getUserInfo(true, false);
    };

    const onUnauthorisedHandler = async ()=>{
        setUnauthorised({show: true});
        await getUserInfo(true, false);
    };
    return (
        <AuthorisedPage>
            <LoadingOverlay
                    isLoading={pageState.isLoading}
                    title="Please wait ..."
                    message="Loading Page"
                >
                    <PortalMainLayout title="Redeem a Card/Certificate" isUnauthorised={unauthorised.show}>
                        <RedeemVoucher 
                            key={pageState.key} 
                            companyOptions={companyOptions} 
                            onNewTransaction={onNewTransactionHandler}
                            onTrxCompleted={onTrxCompletedHandler}
                            onUnauthorised = {onUnauthorisedHandler}
                        />
                    </PortalMainLayout>
            </LoadingOverlay>
        </AuthorisedPage>
    );
};

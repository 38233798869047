

import { UserInfoService } from "./user-info-service";



export class UserManagementService extends UserInfoService
{
    constructor(session){
        super(session);
    }
    
   
    getUserManagementUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api/user-management`;
    
   
    getUsersList = async ()=>{
        if(!this.session)
            return null;

        const state = this.getState();

        if(!state || !state.userInfo)
            return null;

        const r = await this.callWebApi('GET', `${this.getUserManagementUrl()}/users`, null);
        
        return r.data;
    }

    getUserRecord = async (id)=>{
        
        if(!this.session)
            return null;

        const state = this.getState();

        
        if(!state || !state.userInfo) //check user role
            return null;

        const r = await this.callWebApi('GET', `${this.getUserManagementUrl()}/user/${id}`, null);
        
        return r.data;
    }


    deleteUser = async (guid)=>{
        
        if(!this.session)
            return null;

        const state = this.getState();

        
        if(!state || !state.userInfo) //check user role
            return null;

        const r = await this.callWebApi('DELETE', `${this.getUserManagementUrl()}/user/${guid}`, null);
        
        return r.data;
    }

    
    confirmUser = async (guid)=>{
        
        if(!this.session)
            return null;

        const state = this.getState();

        
        if(!state || !state.userInfo) //check user role
            return null;

        const r = await this.callWebApi('POST', `${this.getUserManagementUrl()}/confirm-user/${guid}`, null);
        
        return r.data;
    }

    saveUserRecord = async (model)=>{
        
        if(!this.session)
            return null;

        const state = this.getState();

        
        if(!state || !state.userInfo) //check user role
            return null;

        const r = await this.callWebApi('POST', `${this.getUserManagementUrl()}/user`, model);
        
        return r.data;
    }
} 

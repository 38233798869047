import { useContext, useState } from "react";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { FormInput } from "../form/input";
import { PageContainer } from "../layout/page-container";
import { ToggleView } from "../views/toggle-view";
import { Alert, Alerts } from "../bootstrap/alert";
import { LoadingOverlay } from "../layout/loading-overlay";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CoreServiceSelector } from "../../services/core/service-selector";
import styled from "styled-components";
import { FormatDecimal } from "../../library/core/formats";
import { PullVoucherType } from "./voucher-type";
import { ButtonsPanel } from "../common/buttons-panel";
import { CardNumberBox } from "../common/card-number-box";


const ReadonlyInput = styled.span`
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border:none;
    appearance: none;
`;

export const RedeemCertificateVoucher = ({
    dataModel, 
    transactionGroupId,
    serviceDispatcher,
    onRedeemSuccess,
    otherButtons,
    otherPanels,
    onError,
    onUnauthorised
})=>{
    const {state, dispatch} = useContext(CognitoContext);

    const [formState, setFormState] = useState({
        isProcessing: false,
        totalBillAmount: 0,
        transactionGroupId
    });


    const newModel = {
        ...dataModel,
        ProductName: dataModel.voucher.ProductName,
        amount: dataModel.voucher.Balance
    };
    const [model] = useState(newModel || {});

    const redeemCard = async ()=>{
        let service = null;
        if(serviceDispatcher && typeof serviceDispatcher === 'function')
            service = await serviceDispatcher();
        else
            service = CoreServiceSelector.GetRedemptionService(state.session);
        
        setFormState({
            ...formState,
            isProcessing: true
        });

       
        service.redeemCard({
            CompanyId: model.companyId,
            CardNumber: model.cardNumber,
            PinCode: model.pinCode,
            Amount: model.amount,
            TotalBillAmount: model.totalBillAmount,
            TransactionGroupId: formState.transactionGroupId
        }).then((result)=>{

            setFormState({
                ...formState,
                isProcessing: false
            });

            if(result.Success)
            {
                onRedeemSuccess && onRedeemSuccess({
                    redeemedAmount: result.Amount,
                    transactionGroupId: result.TransactionGroupId
                });
            }
            else
            {
                onError&& onError({
                    errorMessage: getErrorMessage(result)
                });
            }

        }).catch((err)=>{       
            onUnauthorised && err && err.response && err.response.status === 401 && onUnauthorised();

            setFormState({
                ...formState,
                isProcessing: false
            });

            onError&& onError({
                errorMessage: getErrorMessage(err.response.data)
            });
        });

    };

    const getErrorMessage = (data) => {
        if (data.ErrorCode === "INSUFFICIENT_FUNDS")
            return `No value redeemed from this gift card, the full bill remains owing.Balance on card is less than ${data.amount}`
        else if (data.ErrorCode === "41")
            return "Incorrect pin number"
        else if (data.ErrorCode === "6")
            return "Card is expired, please use another card"
        else
            return data.ErrorMessage
    }


    const onSubmitRedemption =  (event)=>{
        event.preventDefault();
        setFormState({
            ...formState,
            isProcessing: true
        });
        redeemCard();
    };

    return (
        <>
            <div>
                <LoadingOverlay 
                    isLoading={formState.isProcessing} 
                    title="Please wait ..."
                    message="Card redemption in progress"
                >
                    <form>
                        <div className="mb-3">
                            <label htmlFor="amount" className="form-label">Amount to redeem from gift certificate:</label>
                            <ReadonlyInput>
                                ${FormatDecimal(model.amount,2)}
                            </ReadonlyInput>
                        </div>

                        <div>
                            {otherPanels}
                        </div>
                        <ButtonsPanel>
                            <button type="submit" className="btn btn-primary" onClick={onSubmitRedemption}>Redeem</button>
                            {otherButtons}
                        </ButtonsPanel>
                        
                        
                    </form>
                </LoadingOverlay>
            </div>
        </>
    );
};
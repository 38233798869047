import { useContext, useEffect, useState } from "react";
import { SignUpForm } from "../../components/auth/signup.form";
import { CognitoSignup } from "../../library/cognito/signup";
import { CognitoContext } from '../../contexts/cognito/cognito-provider';
import { AuthContainer } from "../../components/auth/container";
import { ToggleView } from "../../components/views/toggle-view";
import { Alert, Alerts } from "../../components/bootstrap/alert";
import { VerifyCodeForm } from "../../components/auth/verify-account.form";
import { CurrentUserStorage } from "../../library/core/current-user";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CoreServiceSelector } from "../../services/core/service-selector";
import { LoadingOverlay } from "../../components/layout/loading-overlay";



const SignUpPage = ()=>{
    const cognitoContext = useContext(CognitoContext);
    const navigate = useNavigate();
    const [queryStr] = useSearchParams();
    const [signupInfo, setSignupInfo] = useState({});
    const [state, setState] = useState({
        showSignup: true,
        hasErrors: false,
        verifyCode: false,
        errorMessage: null,
        username: '',
        isLoading: false,
        signupComplete: false
    });

    const getSignupInfo = async () =>{
        
        const guid = queryStr.get('guid');
        const id = queryStr.get('id');
       
        if(!guid || !id)
        {
            navigate('/signin');
            return;
        }
        
        const service = CoreServiceSelector.GetSignupInfoService(null);
        if(!service)
        {
            navigate('/signin');
            return;
        }

        setState({
            ...state,
            isLoading: true
        });

        const data =  await service.getSignupInfo(id, guid).catch((err)=>{
            
            const message = err.response.data || err.message;
            setState({
                ...state,
                hasErrors: true,
                errorMessage: message
            });

        
            // navigate('/signin');
            // return;
        
        });

       if(!data){
            navigate('/signin');
            return;
       }
        
        setState({
            ...state,
            isLoading: false
        });

        setSignupInfo({
            ...data,
            invitationGuid: guid
        });
    };

    useEffect(()=>{
            getSignupInfo();
    },[queryStr]);

    const onSignupErrors = (err)=>{
    
            setState({
                ...state,
                hasErrors: true,
                errorMessage: err,
                isLoading: false
            });
    };


    const signupHandler = (model)=>{
        
        const [firstName,  ...otherNames] = model.fullName.split(" ");
        const lastName=otherNames.join(" ");

        CurrentUserStorage.saveCurrentUser(model.email);
        
        setState({
            ...state,
            username: model.email, 
            password: model.password,
            isLoading: true
        });

        CognitoSignup({
            cognitoContext: cognitoContext.state,
            username: model.email, 
            password: model.password,
            attrs: {
                name: firstName,
                family_name: lastName,
                phone_number: model.phone,
                "custom:company": model.companyName,
                "custom:partnerguid": model.companyGuid,
                "custom:invitationGuid": model.invitationGuid
            },
            onSuccess: (result)=>{
                setState({
                    ...state,
                    verifyCode: true,
                    hasErrors: false,
                    errorMessage: null,
                    user: result.user,
                    isLoading: false
                });
                
            },
            onError: onSignupErrors
            
        });
    };

    const onSignupComplete = ()=>{
        
        setState({
            ...state,
            signupComplete: true,
            showSignup: false,
            verifyCode: false
        });

    };

    return (
        <AuthContainer>
            <LoadingOverlay isLoading={state.isLoading} title="Please wait ..." message="loading">
                <ToggleView show={state.hasErrors}>
                    <Alert type={Alerts.danger}>{state.errorMessage}</Alert>
                </ToggleView>

                <ToggleView show={state.showSignup && !state.verifyCode}>
                    <SignUpForm signupHandler={signupHandler} data={signupInfo}/>
                </ToggleView>
                
                <ToggleView show={state.verifyCode}>
                    <Alert type={Alerts.success}>
                        <p>
                        We've sent a verification code to your email address. Please enter the code to complete your account setup. 
                        </p>
                    </Alert>
                    <VerifyCodeForm username={state.username} onSuccess={onSignupComplete} onError={onSignupErrors}/>
                </ToggleView>

                <ToggleView show={state.signupComplete && !state.hasErrors}>
                    <Alert type={Alerts.success}>
                        <p>Well done! Your account had been created successfully</p>
                    </Alert>
                    <div>
                        <a target="_self" href="/signin">click to sign in</a>
                    </div>
                </ToggleView>
            </LoadingOverlay>
        </AuthContainer>
    );
};

export {SignUpPage};
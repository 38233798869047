import { FormInput } from "./input";

export const FormRenderer = ({formView, dataModel, errorsModel, onChange})=>{
    if(!formView || !formView.fields)
        return null;

    return (
        <>
        {
            formView.fields.map(field => <FormInput
                                            key={field.name} 
                                            dataModel={dataModel}
                                            {...field}
                                            errorsModel={errorsModel}
                                            onChange={onChange}
                                            />
                                )
        }
        </>
    );

};
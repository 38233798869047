import styled from "styled-components";

const DataRow = ({
    className,
    fields,
    data
})=>{
    
    return (
    <tr className={className}>
        {
            fields.map((field, index) => (
                <td 
                    key={index} 
                    className={field.className} 
                    colSpan={field.colspan}
                >
                    {
                        field.isCustom?(field.render(data)):(data[field.name])
                    }
                </td>
            ))
        }
    </tr>
    );
};

//min-height: 500px;
const Table = styled.table``;

export const DataTable = ({
    fields, rows
})=>{
    return (
        <Table className="table table-striped table-hover">
            <thead className="table-dark">
                <tr>
                {
                    fields.map((field, index) => (
                        <td  key={index} className={field.className}>
                        {
                            field.label
                        }
                        </td>
                    ))
                }
                </tr>
            </thead>
            <tbody>
            {
                rows.map((row, index) => <DataRow key={index} fields={fields} data={row}/>)
            }
            </tbody>
        </Table>
    );
};


import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { MainRoutedPage } from './routes/app-routes';
import { AppNavBar } from './components/app-nav';
import Footer from './components/app-footer/footer';
import styled from 'styled-components';

import { useContext, useEffect, useState } from 'react';
import { UnderMaintenance } from './pages/under-maintenance';
import { CognitoContext } from './contexts/cognito/cognito-provider';
const AppPage = styled.div`
  overflow-y:hidden;
  padding-top: 2px;
  padding-bottom: 60px;
  margin-top: 0;
  @media (min-width: 768px){ padding-top: 70px;}
  @media (min-width: 980px){ padding-top: 15px;}
  &.signedin{
    @media (min-width: 321px) and (max-width: 375px){ margin-top:5rem;}
    @media (min-width: 376px) and (max-width: 575px){ margin-top:6.5rem;}
    @media (min-width: 576px) { margin-top: 5px;}
  }
`;

//@media (max-width: 575px){ margin-top:6.5rem;}

function App() {  
    const [redemptionCodeId, setRedemptionCodeId] = useState(null);
    const {state, dispatch} = useContext(CognitoContext);
    const isLoggedIn = state.session && state.cognitoUser;

    function setRedemptionCode(PassCode) {
      setRedemptionCodeId(PassCode); 
    }
    var redeemCode=redemptionCodeId;

    if(process.env.REACT_APP_UNDER_MAINTENANCE && process.env.REACT_APP_UNDER_MAINTENANCE.toLocaleLowerCase() === 'true')
    {
      return <UnderMaintenance />
    }
    return (
    <Router>
      <div className="App">
              <AppNavBar redeem={redeemCode}/>
        <AppPage className={`routed-page ${isLoggedIn?"signedin":""}`}>
          <MainRoutedPage setRedemptionCode={setRedemptionCode} />
        </AppPage>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;

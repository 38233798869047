

export class MockRedemptionService
{
    constructor(session){
        
    }

    getRedemptionsHistory = ()=>{
        const data = [
            {
                id: 1,
                company: 'Apple Review Restaurant - for app review',
                receiptNo: '0033651',
                redemptionDate: '21/12/2021 1:17:48 PM',
                amount: '$10.00',
                cardNumber: '60894100XXX10006669X',
                status: 'Paid',
                userName: 'Front Desk'
            },
            {
                id: 2,
                company: 'Apple Review Restaurant - for app review',
                receiptNo: '0033652',
                redemptionDate: '22/12/2021 1:17:48 PM',
                amount: '$40.00',
                cardNumber: '60894100XXX10006669X',
                status: 'Paid',
                userName: 'Front Desk'
            }
        ];
       
        return new Promise(resolve =>{
            return resolve(data);
        });
    }

    getRedemptionRecord = (redemptionId, type)=>{
        const data = {
            "addressLine1": "Shop 6008",
            "addressLine2": "500 Oxford Street",
            "suburb": "Bondi Junction",
            "postcode": "2021",
            "state": "NSW",
            "country": "Australia",
            "givexTrxId": "932184",
            "remainingBalance": "$86.00",
            "expiryDate": "10/03/2026",
            "id": 538,
            "company": "The Grill House Bondi Junction",
            "receiptNo": "0000538",
            "redemptionDate": "27/03/2023 11:42:08",
            "amount": "$2.00",
            "cardNumber": "60894100XXX10005143X",
            "status": "New",
            "userName": "abdullah ali",
            "transactionGroupId": null,
            "totalRedeemed": null,
            "success": true
        };
        
        return new Promise(resolve =>{
            return resolve(data);
        });
    }



    redeemCard = (request)=>{
        const { 
            CardNumber,
            PinCode,
            Amount,
            Source,
            DeviceType,
            OsName,
            DeviceSerial,
            TotalBillAmount,
            TransactionGroupId,
            IsNewCustomer,
            AppVersion
        } = request;

        const data = {
            success: true,
            redemptionId: 1,
            cardNumber: CardNumber,
            amount: Amount,
            expiryDate: '2025-02-15',
            balanceBefore: Amount,
            balanceOnCard: 0.00,
            errorMessage: null,
            errorCode: null,
            transId: 1,
            authCode: 2,
            canTryAgain: false
        };
        
        return new Promise(resolve =>{
            return resolve(data);
        });
    }
} 


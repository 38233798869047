
export class MockUserInfoService
{
    constructor(session){
        
    }

    getUserInfo = ()=>{
        const data = {
            "firstName":"Thomas",
            "lastName":"Anderson",
            "email":"testuser@bestgiftgroup.com",
            "id":"144",
            "companyName":"Aurora Spa Retreat @ The Prince",
            "companyGuid":"83f98982-4c17-4a84-ab8c-cc78c3ad34b5",
            "phone":"+61786548765",
            "dateOfBirth":null,
            "ipAddress":"172.0.0.1",
            "emailIsVerified":true,
            "partnerCompany":{
                "partnerCompanyId":144,
                "vendorKind":1,
                "companyName":"Aurora Spa Retreat @ The Prince",
                "partnerUniqueId":"83f98982-4c17-4a84-ab8c-cc78c3ad34b5",
                "isActive":true,
                "captureCustomerInfo":false
            }
        };
        
        return new Promise(resolve =>{
            return resolve(data);
        });
    }
    getUserMenu = ()=>{
        
        const data = {
            title: 'Main Menu',
            items:[
                {
                    title: 'Best Gift Cards',
                    icon: 'bi-credit-card-2-back',
                    items:[
                        {
                            title: 'Redeem a Card',
                            icon: 'bi-upc-scan',
                            url:'/redeem-gift-card'
                        },
                        {
                            title: 'Redemption History',
                            icon: 'bi-graph-up-arrow',
                            url: '/redemption-history'
                        }
                    ]
                },
                {
                    title: 'Best Gift Certificates',
                    icon: 'bi-award'
                },
                {
                    title: 'Best Restaurants Listing',
                    icon: 'bi-info-circle'
                },
                {
                    title: 'Best Beauty Listing',
                    icon: 'bi-bag-heart'
                },
                {
                    title: 'Best Pets Listing',
                    icon: 'bi-android'
                },
                {
                    title: 'Best Gift Certificates Listing',
                    icon: 'bi-info-circle'
                },
                {
                    title: 'Team Management',
                    icon: 'bi-people'
                },
                {
                    title: 'Company Profile',
                    icon: 'bi-bank'
                },
                {
                    title: 'User Profile',
                    icon: 'bi-person-lines-fill'
                }
            ]
        };

        return new Promise(resolve =>{
            return resolve(data);
        });
    }
} 

import { useContext, useEffect, useState } from "react";
import { DataTable } from "../../components/bootstrap/data-table";
import { LoadingOverlay } from "../../components/layout/loading-overlay";
import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { AuthorisedPage } from "../auth/authorised-page";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { useNavigate, useParams } from "react-router-dom";
import { PortalUserForm } from "../../components/users/edit-user";
import { CoreServiceSelector } from "../../services/core/service-selector";
import { AppRouteNames } from "../../routes/app-routes";



export const EditUserPage = ()=>{
    
    const [data, setData] = useState({});
    const urlParams = useParams();
    const [pageState, setPageState] = useState({
        isLoading: true,
        message: "Loading in progress"
    });
    const [unauthorised, setUnauthorised] = useState({show: false});
    const {state, dispatch} = useContext(CognitoContext);
    const navigate = useNavigate();

    const getRecord = async ()=>{
        const userId = urlParams.id;
        if(!userId)
            return;

        const service = CoreServiceSelector.GetUserManagementService(state.session);
        if(!service)
            return;

        setPageState({
            ...pageState,
            userId,
            isLoading: true
        });

        const result =  await service
                                    .getUserRecord(userId)
                                    .catch((err)=>{
                                        if(err.response.status === 401){
                                            setUnauthorised({show: true});
                                        }

                                        setPageState({
                                            isLoading: false,
                                            hasErrors: true
                                        });
                                    });
        
        
        if(result)
        {
            
            setData(result);
            setPageState({
                ...pageState,
                isLoading: false,
            });
        }
    };

    useEffect(()=>{
        setPageState({isLoading: true});
        getRecord();
    },[state]);

    const onSaveUser = async (model)=>{
        
        setPageState({
            ...pageState,
            isLoading: true,
            message: "Saving user information"
        });

        const service = CoreServiceSelector.GetUserManagementService(state.session);
        if(!service)
            return;

        
        const result =  await service
                                    .saveUserRecord(model)
                                    .catch((err)=>{
                                        if(err.response.status === 401){
                                            setUnauthorised({show: true});
                                        }

                                        setPageState({
                                            isLoading: false,
                                            hasErrors: true
                                        });
                                    });
        
        
        if(result)
        {
            

            setData(result);
            setPageState({
                ...pageState,
                isLoading: false,
                showSuccess: true,
                successMessage: 'User record saved successfully'
            });
        }

    };

    const onEnableAccount = async (model)=>{
        
        setPageState({
            ...pageState,
            isLoading: true,
            message: "enabling user account"
        });

        const service = CoreServiceSelector.GetUserManagementService(state.session);
        if(!service)
            return;

        
        const result =  await service
                                    .confirmUser(model.Guid)
                                    .catch((err)=>{
                                        if(err.response.status === 401){
                                            setUnauthorised({show: true});
                                        }

                                        setPageState({
                                            isLoading: false,
                                            hasErrors: true
                                        });
                                    });
        
        
        if(result)
        {
            

            setData(result);
            setPageState({
                ...pageState,
                isLoading: false,
                showSuccess: true,
                successMessage: 'User record saved successfully'
            });
        }
    };

    const onDeleteUserAccount = async (model)=>{
       
        setPageState({
            ...pageState,
            isLoading: true,
            message: "deleting user account"
        });

        const service = CoreServiceSelector.GetUserManagementService(state.session);
        if(!service)
            return;

        
        const result =  await service
                                    .deleteUser(model.Guid)
                                    .catch((err)=>{
                                        if(err.response.status === 401){
                                            setUnauthorised({show: true});
                                        }

                                        setPageState({
                                            isLoading: false,
                                            hasErrors: true
                                        });
                                    });
        
        
        if(result)
        {
            navigate(AppRouteNames.usersList);
        }
    };

    return (
        <AuthorisedPage>
            <PortalMainLayout title={`Edit User (${data.FirstName || "..."} ${data.LastName || "..."})`} isUnauthorised={unauthorised.show}>
                <LoadingOverlay 
                    isLoading={pageState.isLoading} 
                    title="Please wait ..."
                    message={pageState.message}
                >
                <PortalUserForm 
                    data={data} 
                    onSave={onSaveUser}
                    onDelete={onDeleteUserAccount}
                    onEnableAccount={onEnableAccount}
                    onLoading={
                        (isLoading) => setPageState({
                            ...pageState,
                            isLoading,
                            message: "Loading in progress"
                        })
                    }/>
                </LoadingOverlay>
               
            </PortalMainLayout>
        </AuthorisedPage>
    );
};


import { useState } from "react";
import { Alert, Alerts } from "../bootstrap/alert";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { FormInput } from "../form/input";
import { CurrentUserStorage } from "../../library/core/current-user";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AppRouteNames } from "../../routes/app-routes";

const LinksBar = styled.div`
  font-size: 13px;
`;


const formView = {
  fields:[
      {
          name: "username",
          label: "Email address",
          type: 'email',
          required: true
      },
      {
          name: 'password',
          label: 'Password',
          type: 'password',
          required: true
      }
  ]
};


const LoginForm = ({
  keepMeLoggedIn,
  username,
  signinHandler,
  onForgotPassword,
  errorMessage
}) => {
  
  const [model, setModel] = useState({
    keepMeLoggedIn,
    username: username || CurrentUserStorage.getCurrentUser()
  });
  const [modelErrors, setModelErrors] = useState({}); 
  const [formState, setFormState] = useState({
    isLoading: false
  });

 
  const onSubmitHandler = (event)=>{
    event.preventDefault();
    const errs = ValidateFormView({fields: formView.fields, model});
    setModelErrors(errs);

    if(!errs || Object.keys(errs).length === 0)
    {
      signinHandler && signinHandler(model);
    }
  };


  const onForgotPasswordhandler = (event)=>{
    event.preventDefault();

    onForgotPassword && onForgotPassword();
  };

  const onFieldChangeHandler = (event) => {
   
     setModel({
         ...model,
         [event.target.name]: GetEventInputValue(event)  
       });
  };

  return (
      <form>
        <h3>Sign In</h3>
        <Alert type={Alerts.danger} hide={!errorMessage}>
          <p>{errorMessage}</p>
        </Alert>

        
          {
              formView.fields.map(field => <FormInput 
                                              key={field.name} 
                                              dataModel={model} 
                                              {...field}
                                              errorsModel={modelErrors}
                                              onChange={onFieldChangeHandler}
                                              />
                                  )
          }

          <div className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="keepMeLoggedIn"
                name="keepMeLoggedIn"
                checked={model.keepMeLoggedIn}
                onChange={onFieldChangeHandler}
              />
              <label className="custom-control-label" htmlFor="keepMeLoggedIn">
                Remember me
              </label>
            </div>
          </div>

          <div className="d-grid">
           <Link to={AppRouteNames.advancedRedeemCard}><button type="submit" className="btn btn-primary" onClick={onSubmitHandler}>
              Submit
            </button></Link> 
          </div>

          <LinksBar className="hstack gap-3">
            <div className="p-2">
              <Link to={AppRouteNames.expressRedemption}>Redeem without login</Link>
            </div>

            <div className="p-2 ms-auto">
              <a href="#" onClick={onForgotPasswordhandler}>Forgot password?</a>
            </div>
          </LinksBar>
       
      </form>

    );
};

export {LoginForm};

import styled from "styled-components";

const DialogContainer = styled.div`
    margin: 20px;
`;

const MessageContainer = styled.div``;

const ButtonsContainer = styled.div`
    margin-top: 15px;
    button {
        min-width: 100px;
        margin-right: 10px;
    }
`;

export const InlineConfirmDialog = ({
    show,
    alertType,
    yesBtnLabel,
    noBtnLabel,
    yesAction,
    noAction,
    body
})=>{
    if(!show)
        return null;

    alertType ||= 'info';
    yesBtnLabel ||= "Yes";
    noBtnLabel ||= "No";
    
    return (
        <DialogContainer className={`alert alert-${alertType}`}>
            <MessageContainer>
                {body}
            </MessageContainer>
            <ButtonsContainer>
                <button type="button" className="btn btn-primary" onClick={yesAction}>{yesBtnLabel}</button>
                <button type="button" className="btn btn-secondary" onClick={noAction}>{noBtnLabel}</button>
            </ButtonsContainer>
        </DialogContainer>
    );
};

import { useEffect, useRef, useState } from "react";
import { RedeemCard } from "../../components/gift-cards/redeem";
import { AdvancedRedeemCard } from "../../components/gift-cards/redeem-advanced";
import { PortalMainLayout } from "../../components/layout/portal-main-layout";
import { ToggleView } from "../../components/views/toggle-view";
import { ExpressLogin } from "../../components/auth/express-login";
import ReCAPTCHA from "react-google-recaptcha";
import { NoLoginService } from "../../services/core/no-login-service";
import { AppRouteNames } from "../../routes/app-routes";
import { useNavigate } from "react-router-dom";
import { RedeemVoucher } from "../../components/gift-cards/redeem-voucher";
import styled from "styled-components";

const CloseButton = styled.span`
    cursor: pointer;
    :hover{
        color: red;
    }
`;

export const ExpressRedemptionPage = (props)=>{
    const reCaptchaRef = useRef();
    const [pageState, setPageState] = useState({
        userInfo: null,
        showForm: false
    });
    const navigate=useNavigate();
    useEffect(() => {
        reloadPage();

    },[]);

    const getRecaptchaToken = async (reset)=>{
        const code = await reCaptchaRef.current.executeAsync();

        if(reset)
            reCaptchaRef.current.reset();

        return code;
    };

    const onLoginSuccess = ({userInfo, passCode})=>{
        if(userInfo)
        {
            setPageState({
                ...pageState,
                showForm: true,
                passCode,
                userInfo
            });
         props.setRedemptionId(passCode)
        }
        else
        {
            setPageState({
                showForm: false
            });
        }
    };

    const serviceDispatcher = async ()=>{
        const captcha = await getRecaptchaToken(true);
        return new NoLoginService({passCode: pageState.passCode, captcha});
    };

    const reloadPage = () => {
      var data = new NoLoginService({ passCode: null });
      var state = data.getState();
      if (state != null && state.userInfo != null) {
        setPageState({
          userInfo: state.userInfo,
          showForm: true,
        });
        props.setRedemptionId(state.userInfo?.id);
      } else {
        setPageState({
          userInfo: null,
          showForm: false,
        });
      }
    }; 
      
    const signOut =async ()=>{
        var obj = new NoLoginService({passCode: null});
       obj.saveState(null);
       props.setRedemptionId(null)
        navigate(AppRouteNames.signin)
    }

    return (
        <>
            <PortalMainLayout title="Redeem a Card Without Login">
                {
                    pageState.showForm?(
                    <>
                        <div>
                            <h3>{pageState?.userInfo?.CompanyName}  <CloseButton onClick={signOut} ><i className="bi bi-x-square"></i></CloseButton></h3>
                        </div>
                        <RedeemVoucher  dataModel={{}} serviceDispatcher={serviceDispatcher} />
                    </>
                    ):<ExpressLogin onLoginSuccess={onLoginSuccess} getRecaptchaToken={getRecaptchaToken}/>
                }

                <ReCAPTCHA                
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                    size="invisible"
                    ref={reCaptchaRef}
                />

            </PortalMainLayout>
        </>
    );
};

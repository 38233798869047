import { CognitoUserPool } from "amazon-cognito-identity-js";
import { createContext, useReducer } from "react";


const initialState = {
	userPool: new CognitoUserPool({
		UserPoolId: process.env.REACT_APP_USER_POOL_ID,
		ClientId: process.env.REACT_APP_POOL_CLIENT_ID
	}),

	region: process.env.REACT_APP_USER_POOL_REGION
};
const CognitoContext = createContext(initialState);


const { Provider } = CognitoContext;

const UserProviderActions = {
	setSession: 'setSession',
	setCognitoUser: 'setCognitoUser',
	setUserInfo: 'setUserInfo',
	signout: 'signout'
};

const clearUserSession = ()=>{
	localStorage.clear();
};

const UserProvider = ( { children } ) => {
	
	const [state, dispatch] = useReducer((state, action) => {
	  state = state || initialState;
	  switch(action.type) {
		case UserProviderActions.setSession:
			return {
				...state,
				session: action.session
			};
		case UserProviderActions.setUserInfo:
			return {
				...state,
				userInfo: action.userInfo
			};
		case UserProviderActions.signout:
			clearUserSession();
			return {
				...state,
				session: null,
				cognitoUser: null
			};
		case UserProviderActions.setCognitoUser:
			return {
				...state,
				cognitoUser: action.cognitoUser
			};
		  
		default:
		  return state;
	  };
	}, initialState);
  
	return <Provider value={{ state, dispatch }}>{children}</Provider>;
  };


export {CognitoContext, UserProvider, UserProviderActions};



import { format, parseISO } from "date-fns";
import { useState } from "react";
import styled from "styled-components";
import { ToggleView } from "../views/toggle-view";

const ButtonsBar = styled.div`
    padding: 5px 0;
    > button {
        margin-right: 5px;
        min-width: 100px;
    }
`;

const getInvitationUrl = (dataModel)=>{
    let baseUrl = 'https://uat-vendors.best-experiences.com';

    if(process.env.NODE_ENV.toLowerCase() === "production")
        baseUrl = 'https://vendors.bestgiftgroup.com';

    return `${baseUrl}/signup?id=${dataModel.Id}&guid=${dataModel.Guid}`;
};


const PendingStatus = ({dataModel})=>{
    
    const [copyStatus, setCopyStatus] = useState(null);

    if(!dataModel.Id || dataModel.AccountIsCreated)
        return null;
    
    const invitationLink = getInvitationUrl(dataModel);
    const btnStyle = {
        minWidth: "100px"
    };
    const onCopyLinkHandler = (event)=>{
        event.preventDefault();
        setCopyStatus('copying');
        navigator.clipboard.writeText(invitationLink);
        setTimeout(()=>{
            setCopyStatus('done');

            setTimeout(()=>{
                setCopyStatus(null);
            }, 600)

        }, 800);
    };
    return (
        <>
            <div className="mb-3 row">
                <div className="col-2">
                    <label className="form-label">Invitation is sent</label>
                </div>
                <div className="col-2">
                    <input
                        readOnly={true}
                        disabled={true}
                        type="text" 
                        className={"form-control"}
                        value={dataModel.IsSent?"Yes":"No"}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-2">
                    <label className="form-label">Sent on date</label>
                </div>
                <div className="col-2">
                    <input
                        readOnly={true}
                        disabled={true}
                        type="text" 
                        className={"form-control"}
                        value={format(parseISO(dataModel.SentOnDate), "yyyy-MM-dd HH:mm")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-2">
                    <label className="form-label">Expires on date</label>
                </div>
                <div className="col-2">
                    <input
                        readOnly={true}
                        disabled={true}
                        type="text" 
                        className={"form-control"}
                        value={format(parseISO(dataModel.ExpiresOnDate), "yyyy-MM-dd HH:mm")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-2">
                    <label className="form-label">Invitation Link</label>
                </div>
                <div className="col-6">
                    <input
                        readOnly={true}
                        type="text" 
                        className={"form-control"}
                        value={invitationLink}
                    />
                </div>
                <div className="col-2">
                   {
                    !copyStatus?( 
                    <button className="btn btn-outline-primary" onClick={onCopyLinkHandler} style={btnStyle}>
                        Copy
                    </button>):null
                   }
                   {
                    copyStatus==='copying'?( 
                    <span className="btn btn-outline-primary" style={btnStyle}>
                        <i className="bi bi-check2"></i>
                    </span>):null
                   }
                   {
                    copyStatus==='done'?( 
                    <span className="btn btn-outline-primary" style={btnStyle}>
                        <i className="bi bi-check2-all"></i>
                    </span>):null
                   }
                   
                </div>
            </div>
        </>
    );
};

const VerifyAccount = ({show, onEnableAccount})=>{
    const [modalState, setModalState] = useState({});

    if(!show)
        return null;

    const onShowModal = (show)=>{
        setModalState({
            show
        });
    };


    const onHide = ()=>{
        onShowModal(false);
    };

    const onEnableAccountHandler = ()=>{
        onShowModal(false);
        onEnableAccount && onEnableAccount();
    };
    return (
        <>
            <ToggleView show={!modalState.show}>
                <div className="alert alert-warning">
                    <div>The user completed the signup form, but probably didn't receive verification code to confirm account!
                    if you are sure, you can confirm user information is correct and enable the account here
                    </div>
                    <div style={{marginTop: "10px"}}>
                        <button type="button" className="btn btn-danger" onClick={()=>onShowModal(true)}>Enable Account</button>
                    </div>
                </div>
            </ToggleView>
            
            <ConfirmModal show={modalState.show} onClose={onHide} onOk={onEnableAccountHandler}/>
        </>
    );
};


const ConfirmModal = ({show, onClose, onOk})=>{
    if(!show)
        return null;
    
    return (
        <>
            <div className="alert alert-danger">
                <div>
                You are about to enable this user account!<br></br>
                by this you confirm the email address and user information provided are correct. 
                <br></br>
                <strong>Do you want to continue?</strong>
                </div>
                <ButtonsBar>
                    <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                    <button type="button" className="btn btn-danger" onClick={onOk}>Yes</button>
                </ButtonsBar>
               
            </div>  
        </>
    );
};


const getUserStatus = (userRecord)=>{
    if(!userRecord || !userRecord.CognitoStatus)
        return "";
    switch(userRecord.CognitoStatus)
    {
        case "CONFIRMED":
            return "Account is ready";
        case "FORCE_CHANGE_PASSWORD":
            return "Waiting first login";
        case "UNCONFIRMED":
            return "Waiting to accept invitation";
        default:
            return userRecord.CognitoStatus;
    }
};

export const AccountStatus = ({show, dataModel, label, onEnableAccount})=>{
    if(!show || !dataModel || Object.keys(dataModel).length === 0)
        return null;

    const name = "AccountStatus";
    //"FORCE_CHANGE_PASSWORD"
    //"CONFIRMED"
    //"UNCONFIRMED"
    const value = getUserStatus(dataModel);

    return (
        <>
            <VerifyAccount show={dataModel.NeedsVerification} onEnableAccount={onEnableAccount}/>
            <div className="mb-3">
                <label htmlFor={name} className="form-label">{label || name}</label>
                <input
                    readOnly={true}
                    disabled={true}
                    type="text" 
                    className={"form-control"}
                    name={name}
                    id={name}
                    value={value}
                />
            </div>
            <PendingStatus dataModel={dataModel} />
            

        </>
    )
};

import { UserManagementService } from "../core/user-management-service";

export class DevUserManagementService extends UserManagementService
{
    constructor(session){
        super(session);
    }
    
    getUserManagementUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api/user-management`;
    
    getUserMenuUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api/user-info/user-menu`;
    getUserInfoUrl = ()=> `https://dev.best-experiences.com/withlogin-universal-redemptions/api/user-info`;

} 

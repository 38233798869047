
export const PASSWORD_HINT = `Must be minimum of 8 characters including at least one number, one symbol and one uppercase character`;

export const isValidPassword = (value)=>{
        
    return (
      value.length >= 8 &&
      !(/\s+/.test(value)) &&
      (/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{6,256}$/).test(value)
    );
}
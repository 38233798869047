import { useContext, useState } from "react";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { FormInput } from "../form/input";
import { LoadingOverlay } from "../layout/loading-overlay";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CoreServiceSelector } from "../../services/core/service-selector";
import { ButtonsPanel } from "../common/buttons-panel";
import { CardNumberBox } from "../common/card-number-box";

const formView = {
    fields:[
        // {
        //     name: "cardNumber",
        //     label: "Gift card number ",
        //     hint: "Best gift card number.",
        //     required: true,
        //     autoComplete:"off",
        //     validate: (value, dataModel)=> {
        //         if(value.length < 19)
        //             return `Card number should be at least 19 digits`;
        //         return null;
        //     }
        // },
        {
            name: 'pinCode',
            label: 'Pin code',
            hint: 'The 4-digit security pin code for the Best gift card.',
            required: true,
            autoComplete:"off",
            validate: (value, dataModel)=> {
                if(value.length !== 4)
                    return `Pin code should be a 4-digit number`;
                return null;
            }
        },
        {
            name: 'amount',
            label: 'Amount to redeem from gift card',
            hint: 'Available gift card value is shown by default, vary if requested by customer.',
            type: 'number',
            isRequiredMessage: "Amount to redeem from gift card can't be empty",
            required: true,
            validate: (value, dataModel)=> {
                if(value <= 0)
                    return `Redeemed amount should be greater than $0.00`;
                return null;
            }
        }
    ]
};

const getFieldValues = (dataModel)=>{
    if(!dataModel)
        return null;

    
    const remaining = dataModel.totalBillAmount - (dataModel.totalRedeemed || 0);

    const balance = (dataModel.voucher && dataModel.voucher.Balance) || 0;
    const amount = remaining <= balance? remaining : balance;
    
    let companyId = null;
    if(dataModel.companyId){
        companyId = parseInt(dataModel.companyId);
    }

    return {
        companyId,
        cardNumber: dataModel.cardNumber,
        pinCode: dataModel.pinCode,
        amount: amount,
        totalBillAmount: dataModel.totalBillAmount

    };
};

export const RedeemGiftCardVoucher = ({
    dataModel,
    transactionGroupId,
    onRedeemSuccess,
    onError, 
    onUnauthorised,
    otherButtons,
    otherPanels,
    serviceDispatcher
})=>{
    const {state, dispatch} = useContext(CognitoContext);
    const [formState, setFormState] = useState({
        isProcessing: false,
        transactionGroupId
    });

    const [model, setModel] = useState(getFieldValues(dataModel) || {});
    const [errors, setErrors] = useState({});

    const onChangeHandler = (event)=>{
        setModel({
            ...model,
            [event.target.name]: GetEventInputValue(event, true)
        });
    };

    const redeemCard = async ()=>{
        let service = null;
        if(serviceDispatcher && typeof serviceDispatcher === 'function')
            service = await serviceDispatcher();
        else
            service = CoreServiceSelector.GetRedemptionService(state.session);
        
        setFormState({
            ...formState,
            isProcessing: true
        });


        service.redeemCard({
            CompanyId: model.companyId,
            CardNumber: model.cardNumber,
            PinCode: model.pinCode,
            Amount: model.amount,
            TotalBillAmount: model.totalBillAmount,
            TransactionGroupId: formState.transactionGroupId
        }).then((result)=>{
            setFormState({
                ...formState,
                isProcessing: false
            });

            if(result.Success)
            {
                onRedeemSuccess && onRedeemSuccess({
                    redeemedAmount: result.Amount,
                    transactionGroupId: result.TransactionGroupId
                });
            }
            else
            {
                onError && onError({
                    errorMessage: getErrorMessage(result)
                });
            }
        }).catch((err)=>{
            onUnauthorised && err && err.response && err.response.status === 401 && onUnauthorised();


            setFormState({
                ...formState,
                isProcessing: false
            });
            onError&& onError({
                errorMessage: getErrorMessage(err.response.data)
            });
        });
    };

    const getErrorMessage = (data) => {
        if (data.ErrorCode === "INSUFFICIENT_FUNDS" || data.ErrorCode === "9")
            return `No value redeemed from this gift card, the full bill remains owing.Balance on card is less than ${data.amount}`
        else if (data.ErrorCode === "41")
            return "Incorrect pin number"
        else if (data.ErrorCode === "6")
            return "Card is expired, please use another card"
        else
            return data.ErrorMessage
    }

    const onSubmitRedemption =  (event)=>{
        event.preventDefault();
        
        const fields = [...formView.fields];

        const errs = ValidateFormView({fields, model});
        setErrors(errs);
       
        if(!errs || Object.keys(errs).length === 0)
        {   
            setFormState({
                ...formState,
                isProcessing: true
            });

            redeemCard();
        }
    };

    return (
        <>
            <div>
                <LoadingOverlay 
                    isLoading={formState.isProcessing} 
                    title="Please wait ..."
                    message="Card redemption in progress"
                >
                    
                    <form>
                        {
                            formView.fields.map(field => <FormInput 
                                                            key={field.name} 
                                                            dataModel={model} 
                                                            {...field}
                                                            errorsModel={errors}
                                                            onChange={onChangeHandler}
                                                            />
                                                )
                        }
                        <div>
                            {otherPanels}
                        </div>
                        <ButtonsPanel>
                            <button type="submit" className="btn btn-primary" onClick={onSubmitRedemption}>Redeem</button>
                            {otherButtons}
                        </ButtonsPanel>
                        
                        
                    </form>
                </LoadingOverlay>
                
            </div>
        </>
    );
};
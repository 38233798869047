import axios from "axios";
import { ca } from "date-fns/locale";

/*
  TODO:
    - extend this class to create two services
      - Token based WebService
      - ApiKey based WebService
*/


export class CoreService {
    constructor(session){
      this.session = session;
    }

    setUnauthorisedHandler = (handler)=>{
      this.unauthorisedHandler = handler;
    }

    needsToken = ()=> true

    getToken = ()=>{
      
      return this.session? this.session.instance.idToken.jwtToken: '';
    }
  
    getBaseHeaders() {
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      };
      return headers;
    };

    callWebApi = async (method, url, data, idToken) => {
      
       const headers = this.getBaseHeaders();
       
       
       if(this.needsToken())
       {
          headers['Authorization'] = idToken || this.getToken();
       }
      
      try
      {
          return await axios({
            method,
            url,
            headers,
            data
          });
      } 
      catch(err)
      {
        if(err.response.status == 401)
          {
            console.log("Unauthorised");
            this.unauthorisedHandler && this.unauthorisedHandler();
          }

          throw err;
      }
      
    }
}

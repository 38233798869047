import styled from "styled-components";

const mobileWidth = 425;

export const MobileSpan = styled.span`
    display: inline-block;
    @media (min-width: ${1+mobileWidth}px){
        display: none;
    }
`;


export const DesktopSpan = styled.span`
    display: none;
    @media  (min-width: ${1+mobileWidth}px){
        display: inline-block;
    }
`;
import styled from "styled-components";

const Select = styled.select`
    appearance: auto;
`;

export const CompanySelectInput = ({
    dataModel, 
    options,
    label,
    readOnly, 
    onChange, 
    showButton, 
    buttonText,
    onButtonClick,
    companyIdFieldName
})=>{
    companyIdFieldName ||= "companyId";
    dataModel ||= {};
    options ||= [];
    readOnly ||= options.length <= 1;
    
    label ||= options.length === 1? "Venue":"Select a venue";

    const onChangeHandler=(event)=>{
        const value = event.target.value;
        onChange && onChange(value);
    };

    let style={ };
    if(showButton)
    {
        style={
            display: "inline-block",
            width: "50%",
            marginLeft: "20px",
            marginRight: "20px"
        };
    }

    const onButtonClickHandler= ()=>{
        onButtonClick && onButtonClick(dataModel[companyIdFieldName]);
    };

    return ( 
        <div className="mb-3">
        <label htmlFor="companyId" className="form-label">{label}</label>
        <Select
            disabled={readOnly}
            className={"form-control"}
            name="companyId"
            id="companyId"
            value={dataModel[companyIdFieldName]}
            style={style}
            onChange={onChangeHandler}
        >
            {
                options.map(item => <option key={item.id} value={item.id} >{item.name}</option>)
            }
        </Select>
        {showButton?<button type="button" className="btn btn-primary" onClick={onButtonClickHandler}>{buttonText}</button>:null}
        </div>
    );
};

import { useContext, useState } from "react";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { FormInput } from "../form/input";
import { PageContainer } from "../layout/page-container";
import { ToggleView } from "../views/toggle-view";
import { Alert, Alerts } from "../bootstrap/alert";
import { LoadingOverlay } from "../layout/loading-overlay";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CoreServiceSelector } from "../../services/core/service-selector";
import styled from "styled-components";
import { FormatDecimal } from "../../library/core/formats";


const SpanBlock = styled.span`
    display: inline-block;
    padding: 0 3px;
`;

const formView = {
    fields:[
        {
            name: "cardNumber",
            label: "Gift card number ",
            hint: "Best gift card number.",
            required: true,
            validate: (value, dataModel)=> {
                if(value.length < 19)
                    return `Card number should be at least 19 digits`;
                return null;
            }
        },
        {
            name: 'pinCode',
            label: 'Pin code',
            hint: 'The 4-digit security pin code for the Best gift card.',
            required: true,
            validate: (value, dataModel)=> {
                if(value.length !== 4)
                    return `Pin code should be a 4-digit number`;
                return null;
            }
        },
        {
            name: 'amount',
            label: 'Amount to redeem from gift card',
            hint: 'Amount to charge to gift card, up to the total bill amount.',
            type: 'number',
            required: true,
            validate: (value, dataModel)=> {
                if(value <= 0)
                    return `Redeemed amount should be greater than $0.00`;
                return null;
            }
        }
    ]
};

const totalBillField =  {
    name: 'totalBillAmount',
    label: "Customer's total bill amount",
    hint: 'Total amount for customer to pay - Next screen will show the balance to collect after gift card redeemed.',
    type: 'number',
    required: true,
    validate: (value, dataModel)=> {
        if(value <= 0 || value < dataModel.amount)
            return `Total bill amount should be greater than redeemed amount`;
        return null;
    }
};


const LabeledAmount = ({label, amount, digits})=>{
    digits = digits || 2;
    return (
        <div className="mb-3">
            <label className="form-label">{label}</label>
            <div className="form-control" style={{fontWeight:400}}>
                <SpanBlock>${FormatDecimal(amount, digits)}</SpanBlock>
            </div>
        </div>
    );
};

const SuccessMessage = ({
    totalBillAmount, 
    totalRedeemed, 
    digits
})=>{
    digits = digits || 2;
    if(totalRedeemed >= totalBillAmount)
    return (
        <div>
            Successfully redeemed total amount of <SpanBlock>${FormatDecimal(totalRedeemed, digits)}</SpanBlock>
        </div>
    );
    else
    return (
        <div>
        <SpanBlock> ${FormatDecimal(totalRedeemed, digits)}  </SpanBlock>successfully redeemed. 
        Balance to collect is <SpanBlock>${FormatDecimal(totalBillAmount-totalRedeemed, digits)}</SpanBlock> 
        </div>
    );
};

export const AdvancedRedeemCard = ({dataModel, serviceDispatcher})=>{
    const {state, dispatch} = useContext(CognitoContext);

    const [formState, setFormState] = useState({
        isProcessing: false,
        showRemainingAmount: false,
        totalBillAmount: null,
        transactionGroupId: null,
        totalRedeemed: null,
        isSubmitted: false,
        isSuccess: false,
        errorMessage: null
    });

    const [message, setMessage] = useState({
        showMessage: false,
        isSuccess: false,
        errorMessage: null,
        cardNumber: null,
        totalRedeemed: null,
        totalBillAmount: null,
        transactionGroupId: null
    });

    const [model, setModel] = useState(dataModel || {});
    const [errors, setErrors] = useState({});

    const onChangeHandler = (event)=>{
        setModel({
            ...model,
            [event.target.name]: GetEventInputValue(event, true)
        });
    };

    const redeemCard = async ()=>{
        let service = null;
        if(serviceDispatcher && typeof serviceDispatcher === 'function')
            service = await serviceDispatcher();
        else
            service = CoreServiceSelector.GetRedemptionService(state.session);
        
        setFormState({
            ...formState,
            showRemainingAmount: true,
            isProcessing: true
        });

       
        service.redeemCard({
            CardNumber: model.cardNumber,
            PinCode: model.pinCode,
            Amount: model.amount,
            TotalBillAmount: model.totalBillAmount,
            TransactionGroupId: formState.transactionGroupId
        }).then((result)=>{
            if(result.success)
            {
                setFormState({
                    ...formState,
                    isSubmitted: true,
                    isSuccess: result.Success,
                    transactionGroupId: result.TransactionGroupId,
                    totalRedeemed: result.TotalRedeemed,
                    errorMessage: result.ErrorMessage,
                    isProcessing: false
                });

                setMessage({
                    showMessage: true,
                    isSuccess: result.Success,
                    transactionGroupId: result.TransactionGroupId,
                    totalRedeemed: result.TotalRedeemed,
                    cardNumber: result.CardNumber,
                    errorMessage: result.ErrorMessage,
                    totalBillAmount: model.TotalBillAmount,
                    isProcessing: false
                });


                if(result.TotalRedeemed >= model.totalBillAmount)
                { 
                    clearForm(false);
                }
                else 
                { 
                    setModel({
                        ...model,
                        cardNumber: null,
                        pinCode: null,
                        amount: null
                    });
                }
            }
            else
            {
                setFormState({
                    ...formState,
                    isSubmitted: true,
                    isSuccess: result.Success,
                    errorMessage: result.ErrorMessage,
                    isProcessing: false
                });

                setMessage({
                    ...message,
                    showMessage: true,
                    isSuccess: result.Success,
                    errorMessage: result.ErrorMessage,
                });
            
            }

        }).catch((err)=>{          
            setMessage({
                ...message,
                showMessage: true,
                isSuccess: false,
                errorMessage:setErrorMessage(err.response.data),
            });
           
            setFormState({
                ...formState,
                isSubmitted: true,
                isSuccess: false,
                errorMessage: err.response.data.ErrorMessage,
                isProcessing: false
            });
        });

    };
    const setErrorMessage = (data) => {
        if (data.ErrorCode === "INSUFFICIENT_FUNDS")
            return `No value redeemed from this gift card, the full bill remains owing.Balance on card is less than ${data.amount}`
        else if (data.ErrorCode === "41")
            return "Incorrect pin number"
        else if (data.ErrorCode === "6")
            return "Card is expired, please use another card"
        else
            return data.ErrorMessage
    }

    const clearForm = (hideMessage)=>{
        
        setFormState({
            isProcessing: false,
            showRemainingAmount: false,
            totalBillAmount: null,
            transactionGroupId: null,
            totalRedeemed: null,
            isSubmitted: false,
            isSuccess: false,
            errorMessage: null

        });

        setModel({
            cardNumber: null,
            pinCode: null,
            amount: null,
            totalBillAmount: null
        });

        if(hideMessage)
            setMessage({
                showMessage: false
            });
    };

    const onClearForm = (event)=>{
        if(event)
            event.preventDefault();
        clearForm(true);
    };

    const onSubmitRedemption =  (event)=>{
        event.preventDefault();
        
        setMessage({
            showMessage: false
        });

        let fields = [...formView.fields, totalBillField];
        if(formState.transactionGroupId)
            fields = [...formView.fields];

        const errs = ValidateFormView({fields, model});
        setErrors(errs);
       
        if(!errs || Object.keys(errs).length === 0)
        {
            //submit here
            // clear the form
            setFormState({
                ...formState,
                isProcessing: true
            });

            redeemCard();
        }
    };

    return (
        <PageContainer>
            <div>
                <ToggleView show={message.showMessage}>
                    <Alert type={Alerts.danger} hide={message.isSuccess || !message.errorMessage}>
               {message.errorMessage}
                    </Alert>
                    <Alert type={Alerts.success} hide={!message.isSuccess}>
                        <SuccessMessage totalRedeemed={message.totalRedeemed} totalBillAmount={message.totalBillAmount} />
                    </Alert>
                </ToggleView>

                <LoadingOverlay 
                    isLoading={formState.isProcessing} 
                    title="Please wait ..."
                    message="Card redemption in progress"
                    >
                    <form>
                    <ToggleView show={!formState.transactionGroupId}>
                            <FormInput 
                                key={totalBillField.name} 
                                dataModel={model} 
                                {...totalBillField}
                                errorsModel={errors}
                                onChange={onChangeHandler}
                            />
                        </ToggleView>
                        {
                            formView.fields.map(field => <FormInput 
                                                            key={field.name} 
                                                            dataModel={model} 
                                                            {...field}
                                                            errorsModel={errors}
                                                            onChange={onChangeHandler}
                                                            />
                                                )
                        }
                   
                        <ToggleView show={formState.isSubmitted  && (model.totalBillAmount > formState.totalRedeemed)}>
                            <LabeledAmount label={totalBillField.label} amount={model.totalBillAmount} />
                            <LabeledAmount label="Total Redeemed Amount" amount={formState.totalRedeemed} />
                            <LabeledAmount label="Remainder for customer to pay" amount={model.totalBillAmount-formState.totalRedeemed} />
                         </ToggleView>
                        
                         <button type="submit" className="btn btn-primary" onClick={onSubmitRedemption}>Redeem</button>
                        
                        <ToggleView show={formState.transactionGroupId}>
                            <span style={{
                                display: "inline-block",
                                padding: "0 10px"
                            }}>Or</span>
                            <a href="#" onClick={onClearForm}>
                                Start with a new total billing amount 
                            </a>
                        </ToggleView>
                        
                    </form>
                </LoadingOverlay>
                
            </div>
        </PageContainer>
    );
};
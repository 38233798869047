import { useContext, useEffect, useState } from "react";
import { GetEventInputValue, ValidateFormView } from "../form/core";
import { FormInput } from "../form/input";
import { PageContainer } from "../layout/page-container";
import { ToggleView } from "../views/toggle-view";
import { Alert, Alerts } from "../bootstrap/alert";
import { LoadingOverlay } from "../layout/loading-overlay";
import { CognitoContext } from "../../contexts/cognito/cognito-provider";
import { CoreServiceSelector } from "../../services/core/service-selector";
import styled from "styled-components";
import { FormatDecimal } from "../../library/core/formats";
import { PullVoucherType } from "./voucher-type";
import { CaptureTotalBillAmount, TotalBillCapture } from "./total-bill-capture";
import { RedeemGiftCardVoucher } from "./redeem-giftcard-voucher";
import { VoucherTypes } from "../../library/core/enums";
import { RedeemCertificateVoucher } from "./redeem-certificate-voucher";
import { TotalBillPreview } from "./total-bill-preview";
import { RedemptionSuccessMessage } from "./redemption-success";
import { CompanySelectInput } from "../form/company-dropdown";


const RedemptionStep = {
    CaptureTotalBillAmount : 1,
    CaptureNewVoucher: 2,
    RedeemVoucher: 3,
    RedemptionSuccess: 4,
    RedemptionComplete: 5
};



const SpanBlock = styled.span`
    display: inline-block;
    padding: 0 3px;
`;

const LabeledAmount = ({label, amount, digits})=>{
    digits = digits || 2;
    return (
        <div className="mb-3">
            <label className="form-label">{label}</label>
            <div className="form-control" style={{fontWeight:400}}>
                <SpanBlock>${FormatDecimal(amount, digits)}</SpanBlock>
            </div>
        </div>
    );
};


export const RedeemVoucher = ({
    dataModel, 
    serviceDispatcher, 
    companyOptions, 
    onTrxCompleted, 
    onNewTransaction,
    onUnauthorised})=>{
    const {state, dispatch} = useContext(CognitoContext);

    const [formState, setFormState] = useState({
        isProcessing: false,
        showRemainingAmount: false,
        totalBillAmount: 0,
        transactionGroupId: null,
        totalRedeemed: 0,
        isSubmitted: false,
        isSuccess: false,
        errorMessage: null,
        step : RedemptionStep.CaptureTotalBillAmount
    });

    const [message, setMessage] = useState({
        showMessage: false,
        isSuccess: false,
        errorMessage: null,
        cardNumber: null,
        totalRedeemed: 0,
        totalBillAmount: null,
        transactionGroupId: null
    });

    const [model, setModel] = useState(dataModel || {
        totalBillAmount: 0,
        totalRedeemed: 0,
        companyId: companyOptions.defaultCompanyId
    });

    const [errors, setErrors] = useState({});

    const onSetVoucher = (voucher)=>{
        
        setMessage({});

        setFormState({
            ...formState,
            voucher,
            step: RedemptionStep.RedeemVoucher
        });

        setModel({
            ...model,
            voucher: voucher,
            cardNumber: voucher.VoucherNumber
        });
    
        
    };

    const onSetTargetCompany = (companyId)=>{
        setModel({
            ...model,
            companyId
        });
    };

    const onSetTotalBillAmount=({totalBillAmount})=>{
        if(totalBillAmount <= 0)
            return;

        setModel({
            ...model,
            totalBillAmount
        });

        setFormState({
            ...formState,
            step: RedemptionStep.CaptureNewVoucher
        });

    };

    const onUnsetVoucher=()=>{
        setMessage({});
        
        setFormState({
            ...formState,
            voucher: null,
            step: RedemptionStep.CaptureNewVoucher
        });

        setModel({
            ...model,
            voucher: null,
            cardNumber: null
        });
    };

    const onUseAnotherVoucher = (event)=>{
        event.preventDefault();
        onUnsetVoucher();
        setMessage({});
    };

    const onRedeemSuccess = (props)=>{
        const {
            voucherKind,
            voucherNumber,
            redeemedAmount, 
            transactionGroupId
        } = props;

        let step = RedemptionStep.CaptureNewVoucher;

        setModel({
            ...model,
            totalRedeemed: formState.totalRedeemed + redeemedAmount
        });

        setFormState({
            ...formState,
            step,
            voucherKind,
            voucherNumber,
            redeemedAmount,
            transactionGroupId: transactionGroupId,
            totalRedeemed: formState.totalRedeemed + redeemedAmount
        });

        setMessage({
            showRedemptionMessage: true,
            isSuccess: true,
        });

        onTrxCompleted && onTrxCompleted(props);
    };

    const onError = ({
        errorMessage
    })=>{

        setMessage({
            errorMessage,
            showMessage: true,
            isError: true
        });
    };

   
    const onClearForm=()=>{
        setModel( {
            totalBillAmount: 0,
            totalRedeemed: 0,
            companyId: model.companyId
        });
        setMessage({});
        setErrors({});
        setFormState({
            totalBillAmount: 0,
            transactionGroupId: null,
            totalRedeemed: 0,
            companyId: model.companyId,
            step: RedemptionStep.CaptureTotalBillAmount
        });

        onNewTransaction && onNewTransaction();
    };

    const newTrxButton = (<button type="button" className="btn btn-secondary" onClick={onClearForm}>
                           Start a new transaction
                            </button>);

    const startNewButton = (
        <ToggleView show={true || formState.transactionGroupId}>

            <ToggleView show={model.totalBillAmount > formState.totalRedeemed} >
                <span style={{display: "inline-block",padding: "0 10px"}}>Or</span>
            </ToggleView>
            {newTrxButton}
        </ToggleView>);

    const otherButtons = (
        <>
            <button type="button" className="btn btn-secondary" onClick={onUseAnotherVoucher}>
                Use another gift
                {
                    (formState.voucher && formState.voucher.VoucherKind) === VoucherTypes.GiftCard? " card":" certificate"
                }
            </button>
            {startNewButton}
        </>
    );

    const otherPanels = (
        <></>
    );

   
    return (
        <PageContainer>
            <div>
                <ToggleView show={companyOptions}>
                    <CompanySelectInput dataModel={model} {...companyOptions} onChange={onSetTargetCompany}/>
                </ToggleView>
                
                <ToggleView show={formState.step === RedemptionStep.CaptureTotalBillAmount}>
                    <CaptureTotalBillAmount onSetTotalBillAmount={onSetTotalBillAmount} />
                </ToggleView>

                <ToggleView show={message.showMessage}>
                    <Alert type={Alerts.danger} hide={!message.isError || !message.errorMessage}>
                        {message.errorMessage}
                    </Alert>
                </ToggleView>

                <ToggleView show={model.totalBillAmount > 0 && !message.showRedemptionMessage }>
                    <TotalBillPreview 
                        totalBillAmount={model.totalBillAmount}
                        totalRedeemed={formState.totalRedeemed}
                        voucher={model.voucher}
                        />
                </ToggleView>
            
                <RedemptionSuccessMessage 
                    showMessage={message.showRedemptionMessage && model.totalBillAmount > 0 && message.isSuccess}
                    totalBillAmount={model.totalBillAmount}
                    totalRedeemed={formState.totalRedeemed}
                />
            

                <ToggleView show={!(formState.totalRedeemed > 0 && formState.totalRedeemed >= model.totalBillAmount)
                    && formState.step === RedemptionStep.CaptureNewVoucher}>
                    <PullVoucherType
                        dataModel={model}
                        onSetVoucher={onSetVoucher}
                        otherPanels={otherPanels}
                        otherButtons={startNewButton}
                        serviceDispatcher={serviceDispatcher}/>
                </ToggleView>
               
               
                <ToggleView show={formState.voucher && formState.step === RedemptionStep.RedeemVoucher}>
                {
                    !formState.voucher?(null):(
                        formState.voucher.VoucherKind === VoucherTypes.GiftCard?(
                            <RedeemGiftCardVoucher 
                                dataModel={model} 
                                transactionGroupId={formState.transactionGroupId}
                                onRedeemSuccess={onRedeemSuccess}
                                onError={onError}
                                onUnauthorised={onUnauthorised}
                                otherPanels={otherPanels}
                                otherButtons={otherButtons}
                                serviceDispatcher={serviceDispatcher}/>
                        ):(
                            <RedeemCertificateVoucher
                                onRedeemSuccess={onRedeemSuccess}
                                dataModel={model} 
                                transactionGroupId={formState.transactionGroupId}
                                onError={onError}
                                onUnauthorised={onUnauthorised}
                                otherPanels={otherPanels}
                                otherButtons={otherButtons}
                                serviceDispatcher={serviceDispatcher}/>
                        )
                    )
                }
                </ToggleView>

                <ToggleView show={formState.totalRedeemed > 0 && formState.totalRedeemed >= model.totalBillAmount}>
                    {newTrxButton}
                </ToggleView>
            </div>
        </PageContainer>
    );
};